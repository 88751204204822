import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { DataService } from "../../../shared/services/data.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
 
})
export class ContactComponent implements OnInit {
  replyId: string;
  str: any;

  constructor(
    private route: ActivatedRoute,
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
  ) {
    this.route.paramMap.subscribe(params => {
      this.replyId = params.get("id")
    })
    console.log(this.replyId)
    this.str = this.replyId.substring(1);
    console.log( this.str);
    this.dataService.loadContact(this.str)
   }

  ngOnInit(): void {
  }

}
