import { Injectable, NgZone } from '@angular/core';
import { User } from "../services/user";

import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { stringify } from 'querystring';
import firebase from 'firebase/app'
import { Observable, of, Subscription } from 'rxjs';
import { switchMap} from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  profileRef: any;
  profile: any;
  userData: any;
  newEmail: any;
  updateNewEmail: any;

  constructor(  
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,  
    public ngZone: NgZone,
   
    private functions: AngularFireFunctions
    ) { 
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.profileRef = this.afs.doc('users/'+ user.uid).valueChanges().subscribe(data => {
          this.profile = data;

        });
      
       
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(user));
        JSON.parse(localStorage.getItem('user'));

      } else {
       
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
     
     
    })
  }
  updateEmail(email){

    var user = firebase.auth().currentUser;

user.updateEmail(email).then(function() {
  // Update successful.
  // this.newUserEmail = this.afAuth.auth.currentUser;
  console.log(user.uid, email);

 
  })
.catch(function(error) {
  console.log(error);
  alert(error)
  return error
}).then();{
    this.newEmail = this.afs.doc('users/'+ user.uid);
  this.updateNewEmail = this.newEmail.update({
    email: email
  }).then(() => {
    const Toast = Swal.mixin({
     
      toast: true,
      text: 'updated',
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000
    })
  
    

})
  
}
}
updatePassword(){
  var user = firebase.auth().currentUser;
  var auth = firebase.auth();
  var emailAddress = user.email;
  console.log(emailAddress);

  
  auth.sendPasswordResetEmail(emailAddress).then(function() {
    // Email sent.
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 5000
    })
    
    Swal.fire({
      position: 'top-end',
 
      text: 'Check your email!  Pasword reset email sent...',
      showConfirmButton: false,
      timer: 400
    })
  }).catch(function(error) {
    // An error happened.
    alert(error);
  });
}
SignOut() {
  return this.afAuth.signOut().then(() => {
 
    localStorage.setItem('user', null);
      JSON.parse(localStorage.getItem('user'));
    localStorage.removeItem('user');
    this.router.navigate(['/']);
 
    setTimeout(function(){ 
      location.reload(); 
    }, 0);
  })
}
SignIn(email, password) {
  return this.afAuth.signInWithEmailAndPassword(email, password)
    .then((result) => {
      this.ngZone.run(() => {
        this.afAuth.authState.subscribe(user => {
          if(user) {
            this.router.navigate(['/admin/settings']); 

          }});
      });
      // this.SetUserData(result.user);
    }).catch((error) => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
      })
      
      // Toast.fire({
      //   type: 'error',
      //   title: 'Oops..  There was a problem..  Check your credentials'
      // })
     
  
      //window.alert(error.message)
    })
}
async SignUp(email, password, firstName, lastName, phone, goalUnits, goalVolume) {
  return this.afAuth.createUserWithEmailAndPassword(email, password)
    .then((result) => {
      this.afAuth.authState.subscribe(async user => {
        if(user) {
          const phoneNumber = phone.replace(/\D/g,'');
          const vol = goalVolume.replace(/\D/g,'');
          this.afs.collection('users/').doc(user.uid).set({
            uid:user.uid,
            firstName: firstName,
            lastName: lastName,
            phone: phoneNumber,
            email:email,
            role: 'LO',
            units: goalUnits,
            volume: vol
          
    
          }, {merge: true})
      
      this.router.navigate(['admin/dashboard']); 
      
        }});

    }).catch((error) => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        
      })
      
      Toast.fire({
        icon: 'error',
        title: error.message
      })
      window.alert(error.message)
    })
    
}

// Send email verfificaiton when new user sign up
  async SendVerificationMail() {
  return (await this.afAuth.currentUser).sendEmailVerification()
  .then(() => {
   
  })
}

// Reset Forggot password
  async ForgotPassword(passwordResetEmail) {
  const { value: email } = await Swal.fire({
    title: 'Input email address',
    input: 'email',
    inputLabel: 'Your email address',
    inputPlaceholder: 'Enter your email address'
  })
  
  if (email) {
    Swal.fire(`Entered email: ${email}`)
   
    return this.afAuth.sendPasswordResetEmail(email)
  .then(() => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
     
    })
    
    Toast.fire({
      icon: 'success',
      title: 'Password reset email sent, check your inbox.'
    })
 
  }).catch((error) => {
    window.alert(error)
  })
  }
  console.log(passwordResetEmail);
  
}
async getUser() {
  return this.afAuth.currentUser;
}

// Returns true when user is looged in and email is verified
get isLoggedIn(): boolean {
 
  const user = JSON.parse(localStorage.getItem('user'));
  return (user !== null) ? true : false;
}

SetUserData(user) {
  const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
  const userData: User = {
    uid: user.uid,
    email: user.email,
    // displayName: user.displayName,
    photoURL: user.photoURL, 
    firstName: user.firstName,
    lastName: user.lastName,
    role: user.role,
    phone: user.phone
   

    
  }
  return userRef.set(userData, {
    merge: true
  })
}
}
