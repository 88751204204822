import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-lead-search',
  templateUrl: './lead-search.component.html',
 
})
export class LeadSearchComponent implements OnInit {
  leadSearchRef: AngularFirestoreCollection<any>;
  leadSearch: any;
  @ViewChild('table',{static:false}) table: ElementRef;
  confirmation: any;
  teamData: any;
  teamMemberData: any[];
  sean: any;
  matt: any;
  robin: any;
  wadie: any;
  whit: any;
  byron: any;
  bowe: any;
  jason: any;
  travis: any;
  sunday: any;
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor(private route: ActivatedRoute,
    public authService: AuthService,
    public dataService: DataService,
    private afStorage: AngularFireStorage,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    public router: Router,) { 
      this.teamData = this.afs.collection('users', ref=>ref.where('role','==','LO')).valueChanges().subscribe(data => {
        this.teamMemberData= data;
        this.teamMemberData.forEach(item => {
if (item.firstName == 'Sean'){
this.sean = item

}
if (item.firstName == 'Matthew'){
  this.matt = item

  }
  if (item.firstName == 'Robin'){
    this.robin = item
  
    }
    if (item.firstName == 'Wadie'){
      this.wadie= item
    
      }
      if (item.firstName == 'Whitney'){
        this.whit = item
      
        }
        if (item.firstName == 'Byron'){
          this.byron = item
        
          }
          if (item.firstName == 'Robert'){
            this.bowe = item
          
            }
            if (item.firstName == 'Jason'){
              this.jason = item
            
              }
              if (item.firstName == 'Travis'){
                this.travis = item
              
                }
                if (item.firstName == 'Sunday'){
                  this.sunday = item
                
                  }
        })
      })
    }
    search(word){
      console.log(word)
      this.leadSearchRef = this.afs.collection('referrals', ref => ref.where('array', 'array-contains', word));
      this.leadSearch = this.leadSearchRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
  
          return { id, ...data };
        }))
      );
    }

  ngOnInit(): void {
  }

}
