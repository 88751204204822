import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-political',
  templateUrl: './political.component.html',
  styleUrls: ['./political.component.css']
})
export class PoliticalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
