import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { DataService } from "../../../shared/services/data.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-card-lead',
  templateUrl: './card-lead.component.html'
})
export class CardLeadComponent implements OnInit {
  replyId: string;
  str: any;
  selfgen: boolean;

  constructor(
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    ) {
      this.route.paramMap.subscribe(params => {
        this.replyId = params.get("id")
      })
      this.str = this.replyId.substring(1);
      if (this.str == this.authService?.profile?.uid){
        this.selfgen = true
      }
      else {
        this.selfgen = false
      }
     }

  ngOnInit(): void {
  }

}
