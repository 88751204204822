import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-card-newleads',
  templateUrl: './card-table-newleads.component.html',

})
export class CardTableNewleadsComponent implements OnInit {
  color:any
  constructor(public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,) { }

  ngOnInit(): void {
  }
  delete(id ){
this.afs.doc('referrals/'+ id).delete()
  }
}
