import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-card-shopping',
  templateUrl: './card-table-shopping.component.html',

})
export class CardTableShoppingComponent implements OnInit {
   constructor(public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private firestore: AngularFirestore){

    }
    ngOnInit(){}
  }