import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-political-capital',
  templateUrl: './my-political-capital.component.html',
  styleUrls: ['./my-political-capital.component.css']
})
export class MyPoliticalCapitalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
