
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { ActivatedRoute } from "@angular/router";
import Chart from "chart.js";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import { DataService } from "src/app/shared/services/data.service";


@Component({
  selector: 'app-bar-chart2',
  templateUrl: './card-bar-chart2.component.html',

})
export class CardBarChart2Component implements OnInit {
 
  stats: any;
  contacted: any[];
  dnc: any[];
  myLeads: any;
  statsRef: any;
  closedValue: any[];
  leads: any[];
  contactedAttempt: any[];
  gal: any[];
  arr: any[];
  agentContacted: any;
  attemptingContact: any;
  dncAgent: any;
  agentLeads: any;
  gaveLead: any;
  P: any;
  PA: any;
  CFR: any;
  CR: any;
  dead: any;
  SA: any;
  closed: any;
  NA: any;
  FO: any;
  contactedLead: any;
  contactedAttemptLead: any;
  dncLead: any;
  processingLead: any;
  preapprovedLead: any;
  cfrLead: any;
  crLead: any;
  deadLead: any;
  saLead: any;
  closedLead: any;
  naLead: any;
  foLead: any;
  

  constructor( private route: ActivatedRoute,
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,) {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.arr = []
  // this.notApproved = []
  this.closedValue = []
  this.contacted = []
  this.dnc = []
  this.leads = []
  this.gal = []
  this.contactedAttempt = []
    this.afAuth.authState.subscribe(user => {
      if (user) {
      
        this.statsRef = this.afs.collection('stats/'+ user.uid + '/2021').valueChanges().subscribe(data => {
          this.stats = data;
          this.stats.forEach(item => {
    if (item.status == 'Contacted'){
    // this.contacted.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
    this.contactedLead = item.total
    // console.log(this.contacted)
    }
    if (item.status == 'Attempting Contact'){
      // this.contactedAttempt.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
      // console.log(this.contacted)
      this.contactedAttemptLead = item.total
      }
    if (item.status == 'DNC'){
      // this.dnc.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
      // console.log(this.dnc)
      this.dncLead = item.total
      }
      if (item.status == 'Processing'){
        // this.P.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
        // console.log(this.leads)
        this.processingLead = item.total
        }
        if (item.status == 'Pre-Approved'){
          // this.PA.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
          // console.log(this.leads)
          this.preapprovedLead = item.total
          }
          if (item.status == 'Contacted - Follow up required'){
            // this.CFR.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
            // console.log(this.leads)
            this.cfrLead = item.total
            }
            if (item.status == 'Credit Repair'){
              // this.CR.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
              // console.log(this.leads)
              this.crLead = item.total
              }
              if (item.status == 'Dead'){
                // this.dead.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
                // console.log(this.leads)
                this.deadLead = item.total
                }
                if (item.status == 'Shopping Around'){
                  // this.SA.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
                  // console.log(this.leads)
                  this.saLead = item.total
                  }
                  if (item.status == 'Closed'){
                    // this.closed.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
                    // console.log(this.leads)
                    this.closedLead = item.total
                    }
                    if (item.status == 'Not Approved'){
                      // this.NA.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
                      // console.log(this.leads)
                      this.naLead = item.total
                      }
                      if (item.status == 'Future Opportunity'){
                        // this.FO.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
                        // console.log(this.leads)
                        this.foLead = item.total
                        }
                      });
     
    var config = {
      type: "bar",
      data: {
        labels: [
          "Closed Loans",
          "Proceesing",
          "Pre-Approved",
          "Contacted",
          "Contacted - Follow Up",
          "Future Opportunity",
          "Cretit Repair",
          "Shopping Around",
         
        
        ],
        datasets: [
         
       
          {
            // new Date().getFullYear() - 1,
        
            label: '',
            fill: false, 
            backgroundColor: ["green","#0aea0e","#0bc30f","#0389ff","#0268c2","#00539c","#ff8503","#bf6402"],
            // borderColor: "#D1230F",
            data:[this.closedLead,this.processingLead, this.preapprovedLead, this.contactedLead,this.cfrLead, this.foLead, this.crLead, this.saLead ],
            barThickness: 24,
            type:'bar'
          },
          // {
          //   // new Date().getFullYear() - 2
          //   label: 'Attempting Contact',
          //   fill: false,
          //   backgroundColor: "orange",
          //   borderColor: "orange",
          //   data: [this.attemptingContact],
          //   barThickness: 24,
          //   type:'bar'
          // },
          // {
          //   // new Date().getFullYear()
          //   label: 'Contacted',
          //   backgroundColor: "#0F93D1",
          //   borderColor: "#0F93D1",
          //   data: [this.agentContacted] ,
          //   fill: false,
          //   barThickness: 24,
          //   type:'bar'
          // },
          // {
          //   // new Date().getFullYear() - 2
          //   label: 'Agents with leads',
          //   fill: false,
          //   backgroundColor: "green",
          //   borderColor: "green",
          //   data: [this.gaveLead],
          //   barThickness: 24,
          //   type:'bar'
          // },
         
          // {
          //   label: new Date().getFullYear() - 1+ ' Website Leads',
          //   fill: 'origin',
          //   backgroundColor: 'rgba(5, 5, 5, 0.80)',
          //   borderColor: "#000",
          //   data: this.arr,
          //   barThickness: 12,
          
            
          // },
          // {
          //   xAxisID: 'B',
          //   label: new Date().getFullYear() - 1+ ' Website Leads',
          //   fill: false,
          //   backgroundColor: '#fff',
          //   borderColor: "#000",
          //   data: [5,3,8,5,4,3,9, 8 , 5, 2, 3, 4],
          //   barThickness: 12,
          //   type:'line'
            
          // },
        ],
      },
      options: {
        animation: {
          duration: 0
      },
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Sales Charts",
          fontColor: "white",
        },
        legend: {
          display: false,
          reverse:false,
          labels: {
            fontColor: "white",
          },
          align: "end",
          position: "bottom",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
             
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              stacked: false,
              display: true,
              scaleLabel: {
                display: false,
                // labelString: "Month",
                fontColor: "white",
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
             
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
                beginAtZero: true
              },
              stacked: false,
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
                fontColor: "white",
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: "rgba(255, 255, 255, 0.15)",
                zeroLineColor: "rgba(33, 37, 41, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
           
          
          ],
        },
      },
    //   data: {
    //     labels:  ["Gave Us A Lead",
    //         "Agent Contacted",
    //         "Attempting Contact",
    //         "DNC Agent",
    //         "Leads Generated"],
    //     datasets: [{
    //         // label: '# of Votes',
    //         data: [this.gaveLead, this.agentContacted, this.contactedAttempt, this.dncAgent, this.agentLeads],
    //         backgroundColor: [
    //             'rgba(255, 99, 132, 0.2)',
    //             'rgba(54, 162, 235, 0.2)',
    //             'rgba(255, 206, 86, 0.2)',
    //             'rgba(75, 192, 192, 0.2)',
    //             'rgba(153, 102, 255, 0.2)',
    //             'rgba(255, 159, 64, 0.2)'
    //         ],
    //         borderColor: [
    //             'rgba(255, 99, 132, 1)',
    //             'rgba(54, 162, 235, 1)',
    //             'rgba(255, 206, 86, 1)',
    //             'rgba(75, 192, 192, 1)',
    //             'rgba(153, 102, 255, 1)',
    //             'rgba(255, 159, 64, 1)'
    //         ],
    //         borderWidth: 1
    //     }]
    // },
    // options: {
    //     scales: {
    //         yAxes: [{
    //             ticks: {
    //                 beginAtZero: true
    //             }
    //         }]
    //     }
    // }
    };
    let ctx: any = document.getElementById("line-chart-3") as HTMLCanvasElement;
    var newCharty = new Chart(ctx, config);
    newCharty.data.datasets[0].data[0];
    newCharty.update()
    ctx = ctx.getContext("2d");
   
     var gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, 'rgba(255, 255, 255, 0.44)');   
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0.50)');
    new Chart(ctx, config);
    if(typeof ctx.destroy != "undefined") ctx.destroy();
    ctx = document.getElementById('line-chart-3');
    ctx = ctx.getContext("2d");
    var newGraph = new Chart(ctx, config);
    newGraph.update();

  })

  
}})



  }
 
}
