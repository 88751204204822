
import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { AuthService } from "../../../shared/services/auth.service";

@Component({
  selector: "app-card-settings",
  templateUrl: "./card-settings.component.html",
})
export class CardSettingsComponent implements OnInit {
  constructor(  private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    public authService: AuthService) {
    // this.afAuth.authState.subscribe(user => {
    //   if(user) {
    //     console.log(user.uid)
    //   }
    // else {
    //   console.log('no user')
    // }})

  }

  ngOnInit(): void {}

  update(user, firstName, lastName, email, phone, about, units, volume){
console.log(user, firstName, lastName, email, phone, about)
this.afs.collection('users').doc(user).update({
  about:about,
  firstName:firstName,
  lastName:lastName,
  email:email, 
  phone:phone, 
  units: units,
  volume: volume
 
})
  }
}