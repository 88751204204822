import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-card-incomingleads',
  templateUrl: './card-table-incomingleads.component.html',

})
export class CardTableIncomingleadsComponent implements OnInit {
  replyId: string;
  str: any;
  color: any;

  constructor(private route: ActivatedRoute,
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,) { 
      // this.route.paramMap.subscribe(params => {
      //   this.replyId = params.get("id")
      // })
      // this.str = this.replyId.substring(1);
      // this.dataService.loadAgentLeads(this.str)
     }

  ngOnInit(): void {
  }

}
