import { Component, OnInit } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/functions";
import { ActivatedRoute } from "@angular/router";
import { DataService } from "src/app/shared/services/data.service";
import { AuthService } from "../../../shared/services/auth.service";

@Component({
  selector: "app-card-profile",
  templateUrl: "./card-profile.component.html",
})
export class CardProfileComponent implements OnInit {
  replyId: string;
  str: any;
  confirmation: any;
  constructor( public authService: AuthService, public dataService: DataService,private route: ActivatedRoute,private functions: AngularFireFunctions,) {


  }

  ngOnInit(): void {}

  async logCreation(uid){
console.log(uid)
const fun = this.functions.httpsCallable('updateUserCreationDate');
    this.confirmation = await fun({
    
     uid:uid,

  
    }).toPromise();
    return fun;
  }
}
