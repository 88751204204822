import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";
import { ProfileComponent } from "./views/profile/profile.component";

// environments
import { environment } from './../environments/environment';


// components for views and layouts

import { AdminNavbarComponent } from "./components/navbars/admin-navbar/admin-navbar.component";
import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";
import { CardBarChartComponent } from "./components/cards/card-bar-chart/card-bar-chart.component";
import { CardLineChartComponent } from "./components/cards/card-line-chart/card-line-chart.component";
import { CardPageVisitsComponent } from "./components/cards/card-page-visits/card-page-visits.component";
import { CardProfileComponent } from "./components/cards/card-profile/card-profile.component";
import { CardSettingsComponent } from "./components/cards/card-settings/card-settings.component";
import { CardSocialTrafficComponent } from "./components/cards/card-social-traffic/card-social-traffic.component";
import { CardStatsComponent } from "./components/cards/card-stats/card-stats.component";
import { CardTableComponent } from "./components/cards/card-table/card-table.component";
// import { CardTableDeadComponent } from "./components/cards/card-table-dead/card-table-dead.component";
import { FooterAdminComponent } from "./components/footers/footer-admin/footer-admin.component";
import { FooterComponent } from "./components/footers/footer/footer.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { HeaderStatsComponent } from "./components/headers/header-stats/header-stats.component";
import { IndexNavbarComponent } from "./components/navbars/index-navbar/index-navbar.component";
import { MapExampleComponent } from "./components/maps/map-example/map-example.component";
import { IndexDropdownComponent } from "./components/dropdowns/index-dropdown/index-dropdown.component";
import { TableDropdownComponent } from "./components/dropdowns/table-dropdown/table-dropdown.component";
import { PagesDropdownComponent } from "./components/dropdowns/pages-dropdown/pages-dropdown.component";
import { NotificationDropdownComponent } from "./components/dropdowns/notification-dropdown/notification-dropdown.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { UserDropdownComponent } from "./components/dropdowns/user-dropdown/user-dropdown.component";
import { CreateComponent } from './views/admin/create/create.component';
import { CardLeadComponent } from './components/cards/card-lead/card-lead.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageModule } from '@angular/fire/storage';
// import { NgxStripeModule } from '@nomadreservations/ngx-stripe';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { MomentModule } from 'angular2-moment';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ContactComponent } from './views/admin/contact/contact.component';
import { CardContactComponent } from './components/cards/card-contact/card-contact.component';
import { CardHistoryComponent } from './components/cards/card-history/card-history.component';
import { CardMessagesComponent } from './components/cards/card-messages/card-messages.component';
import { HttpClientModule } from "@angular/common/http";
import { CardTableDeadComponent } from "./components/cards/card-table-dead/card-table-dead.component";
import { CardTableContactedComponent } from './components/cards/card-table-contacted/card-table-contacted.component';
import { CardTableAttemptingComponent } from './components/cards/card-table-attempting/card-table-attempting.component';
import { CardTableHasleadComponent } from './components/cards/card-table-haslead/card-table-haslead.component';
import { LeadsComponent } from './views/admin/leads/leads.component';
import { LeadComponent } from './views/admin/lead/lead.component';
import { CardTableNewleadsComponent } from './components/cards/card-table-newleads/card-table-newleads.component';
import { CardTableLeadsworkingComponent } from './components/cards/card-table-leadsworking/card-table-leadsworking.component';
import { CardTableLeadhistoryComponent } from './components/cards/card-table-leadhistory/card-table-leadhistory.component';
import { CardTableLeadinfoComponent } from './components/cards/card-table-leadinfo/card-table-leadinfo.component';
import { CardLeadChartComponent } from './components/cards/card-lead-chart/card-lead-chart.component';
import { CardAgentLeaderboardComponent } from './components/cards/card-agent-leaderboard/card-agent-leaderboard.component';
import { ClosedComponent } from './views/admin/closed/closed.component';
import { ProcessingComponent } from './views/admin/processing/processing.component';
import { PreapprovedComponent } from './views/admin/preapproved/preapproved.component';
import { ContactedComponent } from './views/admin/contacted/contacted.component';
import { FollowupComponent } from './views/admin/followup/followup.component';
import { FutureComponent } from './views/admin/future/future.component';
import { CreditComponent } from './views/admin/credit/credit.component';
import { ShoppingComponent } from './views/admin/shopping/shopping.component';
import { DNCComponent } from './views/admin/dnc/dnc.component';
import { DeadComponent } from './views/admin/dead/dead.component';
import { NotapprovedComponent } from './views/admin/notapproved/notapproved.component';
import { SentleadsComponent } from './views/admin/sentleads/sentleads.component';
import { AgentdncComponent } from './views/admin/agentdnc/agentdnc.component';
import { AttemptingComponent } from './views/admin/attempting/attempting.component';
import { AgentcontactedComponent } from './views/admin/agentcontacted/agentcontacted.component';
import { CardTableClosedComponent } from './components/cards/card-table-closed/card-table-closed.component';
import { CardTableProcessingComponent } from './components/cards/card-table-processing/card-table-processing.component';
import { CardTablePreapprovedComponent } from './components/cards/card-table-preapproved/card-table-preapproved.component';
import { CardTableLeadcontactedComponent } from './components/cards/card-table-leadcontacted/card-table-leadcontacted.component';
import { CardTableFutureComponent } from './components/cards/card-table-future/card-table-future.component';
import { CardTableCreditComponent } from './components/cards/card-table-credit/card-table-credit.component';
import { CardTableNotapprovedComponent } from './components/cards/card-table-notapproved/card-table-notapproved.component';
import { CardTableShoppingComponent } from './components/cards/card-table-shopping/card-table-shopping.component';
import { CardTableDncComponent } from './components/cards/card-table-dnc/card-table-dnc.component';
import { CardTableDeadleadsComponent } from './components/cards/card-table-deadleads/card-table-deadleads.component';
import { CardTableFollowComponent } from './components/cards/card-table-follow/card-table-follow.component';
import { CardTableMyleadsComponent } from './components/cards/card-table-myleads/card-table-myleads.component';
import { CardTableIncomingleadsComponent } from './components/cards/card-table-incomingleads/card-table-incomingleads.component';
import { ControlboardComponent } from './views/admin/controlboard/controlboard.component';
import { FormComponent } from './views/form/form.component';
import { CardLineChart2Component } from './components/cards/card-line-chart2/card-line-chart2.component';
import { CardBarChart2Component } from './components/cards/card-bar-chart2/card-bar-chart2.component';
import { EditLeadComponent } from './components/cards/edit-lead/edit-lead.component';
import { CardLeaderboardComponent } from './components/cards/card-leaderboard/card-leaderboard.component';
import { CardBarChart3Component } from './components/cards/card-bar-chart3/card-bar-chart3.component';
import { CardLineChart3Component } from './components/cards/card-line-chart3/card-line-chart3.component';
import { TransferComponent } from './views/transfer/transfer.component';
import { MyPoliticalCapitalComponent } from './components/cards/my-political-capital/my-political-capital.component';
import { PoliticalCapitalComponent } from './components/cards/political-capital/political-capital.component';
import { PoliticalComponent } from "./views/admin/political/political.component";
import { CardPieComponent } from './components/cards/card-pie/card-pie.component';
import { DtcLeadsComponent } from './views/admin/dtc-leads/dtc-leads.component';
import { UncontactedComponent } from './views/admin/uncontacted/uncontacted.component';
import { TrainingCardComponent } from "./components/cards/training-card/training-card.component";
import { CardIncomingleadsComponent } from './components/cards/card-incomingleads/card-incomingleads.component';
import { CardTeamActionsComponent } from './components/cards/card-team-actions/card-team-actions.component';
import { CardTeamComponent } from './components/cards/card-team/card-team.component';
import { VAClassRegistrationComponent } from './components/cards/vaclass-registration/vaclass-registration.component';
import { VAClassContactedComponent } from './components/cards/vaclass-contacted/vaclass-contacted.component';
import { VAClassdistributionComponent } from './components/cards/vaclassdistribution/vaclassdistribution.component';
import { AgentSearchComponent } from './views/admin/agent-search/agent-search.component';
import { LeadSearchComponent } from './views/admin/lead-search/lead-search.component';
import { CreateAgentComponent } from './views/admin/create-agent/create-agent.component';
import { NgToggleModule } from "ng-toggle-button";
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    CardBarChartComponent,
    CardLineChartComponent,
    IndexDropdownComponent,
    PagesDropdownComponent,
    TableDropdownComponent,
    NotificationDropdownComponent,
    UserDropdownComponent,
    SidebarComponent,
    FooterComponent,
    FooterSmallComponent,
    FooterAdminComponent,
    CardPageVisitsComponent,
    CardProfileComponent,
    CardSettingsComponent,
    CardSocialTrafficComponent,
    CardStatsComponent,
    CardTableComponent,
    CardTableDeadComponent,
    HeaderStatsComponent,
    MapExampleComponent,
    AuthNavbarComponent,
    AdminNavbarComponent,
    IndexNavbarComponent,
    AdminComponent,
    AuthComponent,
    MapsComponent,
    SettingsComponent,
    TablesComponent,
    LoginComponent,
    RegisterComponent,
    IndexComponent,
    LandingComponent,
    ProfileComponent,
    CreateComponent,
    CardLeadComponent,
    ContactComponent,
    CardContactComponent,
    CardHistoryComponent,
    CardMessagesComponent,
    CardTableContactedComponent,
    CardTableAttemptingComponent,
    CardTableHasleadComponent,
    LeadsComponent,
    LeadComponent,
    CardTableNewleadsComponent,
    CardTableLeadsworkingComponent,
    CardTableLeadhistoryComponent,
    CardTableLeadinfoComponent,
    CardLeadChartComponent,
    CardAgentLeaderboardComponent,
    ClosedComponent,
    ProcessingComponent,
    PreapprovedComponent,
    ContactedComponent,
    FollowupComponent,
    FutureComponent,
    CreditComponent,
    ShoppingComponent,
    DNCComponent,
    DeadComponent,
    NotapprovedComponent,
    SentleadsComponent,
    AgentdncComponent,
    AttemptingComponent,
    AgentcontactedComponent,
    CardTableClosedComponent,
    CardTableProcessingComponent,
    CardTablePreapprovedComponent,
    CardTableLeadcontactedComponent,
    CardTableFutureComponent,
    CardTableCreditComponent,
    CardTableNotapprovedComponent,
    CardTableShoppingComponent,
    CardTableDncComponent,
    CardTableDeadleadsComponent,
    CardTableFollowComponent,
    CardTableMyleadsComponent,
    CardTableIncomingleadsComponent,
    ControlboardComponent,
    FormComponent,
    CardLineChart2Component,
    CardBarChart2Component,
    EditLeadComponent,
    CardLeaderboardComponent,
    CardBarChart3Component,
    CardLineChart3Component,
    TransferComponent,
    MyPoliticalCapitalComponent,
    PoliticalCapitalComponent,
    PoliticalComponent,
    CardPieComponent,
    DtcLeadsComponent,
    TrainingCardComponent,
    UncontactedComponent,
    CardIncomingleadsComponent,
    CardTeamActionsComponent,
    CardTeamComponent,
    VAClassRegistrationComponent,
    VAClassContactedComponent,
    VAClassdistributionComponent,
    AgentSearchComponent,
    LeadSearchComponent,
    CreateAgentComponent,
  ],
  imports: [BrowserModule, AppRoutingModule,   AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebase),
   
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgToggleModule,
    ClipboardModule, 
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

