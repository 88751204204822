import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-followup',
  templateUrl: './followup.component.html',
 
})
export class FollowupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
