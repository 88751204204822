import { Component, ElementRef, NgZone, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { createPopper } from "@popperjs/core";
import { AuthService } from "src/app/shared/services/auth.service";
import { DataService } from "src/app/shared/services/data.service";

@Component({
  selector: "app-admin-navbar",
  templateUrl: "./admin-navbar.component.html",
})
export class AdminNavbarComponent implements OnInit {
  
  dropdownPopoverShow = false;
  @ViewChild('btnDropdownRef',{ static: false }) btnDropdownRef:ElementRef;
  popper = document.createElement("div");
  replyId: string;
  str: any;
  constructor(public authService: AuthService, public dataService: DataService, public router: Router,private route: ActivatedRoute,private zone: NgZone) {
    Window["myComponent"] = this;
      
   
    this.str = this.authService?.profile?.uid;
    Window["str"] = this.str;
  }

  ngOnInit(): void {
//     this.popper.innerHTML = `<div class="bg-red-500 text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48" #popoverDropdownRef>
//   <a onclick="Window.myComponent.addAgent()"class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-white">
//    New Agent Record
//   </a>
//   <a onclick="Window.myComponent.addBorrower()" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-white">
//     New Borrower Record
//   </a>

// </div>`;
  }

  goToLead(id) {
    this.router.navigate(['/admin/contact/:' + id]);

  }
  leadSearch(){
    this.router.navigate(['/admin/leadSearch' ]);
  }
  agentSearch(){
    this.router.navigate(['/admin/agentSearch' ]);
  }
  addBorrower(id){
  

    this.router.navigate(['/admin/create/:'+ this.authService?.profile?.uid]);
 
 
  }
  addAgent(id){


      this.router.navigate(['/admin/createAgent/:'+ this.authService?.profile?.uid]);
    
  }
  toggleDropdown(){
    if(this.dropdownPopoverShow){
      this.dropdownPopoverShow = false;
      this.destroyPopper();
    } else {
      this.dropdownPopoverShow = true;
      this.createPoppper();
    }
  }
  destroyPopper(){
    this.popper.parentNode.removeChild(this.popper);
  }
  createPoppper(){
    createPopper(this.btnDropdownRef.nativeElement, this.popper, {
      placement: "bottom-start"
    });
    this.btnDropdownRef.nativeElement.parentNode.insertBefore(this.popper, this.btnDropdownRef.nativeElement.nextSibling);

  }
}
