import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/services/auth.service";
// import { AuthService } from "../../../shared/services/auth.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  constructor(public authService: AuthService, ) {}

  ngOnInit(): void {}
}
