import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';

@Component({
  selector: 'app-card-contact',
  templateUrl: './card-contact.component.html'
  
})
export class CardContactComponent implements OnInit {
  replyId: string;
  str: any;
  agentData: any;
  agentDataSet: any;
  LOData: any;
  LODataSet: any;

  constructor(
    private route: ActivatedRoute,
    public messagingService: MessagingService,
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
  ) {
    this.route.paramMap.subscribe(params => {
      this.replyId = params.get("id")
    })
    console.log(this.replyId)
    this.str = this.replyId.substring(1);
    console.log( this.str);
    this.agentData = this.afs.doc('users/'+ this.str).valueChanges().subscribe(data => {
      this.agentDataSet = data;

      this.LOData = this.afs.doc('users/'+ this.agentDataSet.LO).valueChanges().subscribe(data => {
        this.LODataSet = data;
        
       })
      
     })
   }

  ngOnInit(): void {
  }

}
