
import { Injectable,Component, OnInit, AfterViewInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { ActivatedRoute } from "@angular/router";
import Chart from "chart.js";
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-card-bar-chart3',
  templateUrl: './card-bar-chart3.component.html',

})
export class CardBarChart3Component implements OnInit {

  stats: any;
  stat: any;
  myStuff: any;
  statsRef: any;
  closedValue: any[];
  leadsValue: any[];
  leaderData: any;
  leaders: any[];
  names: any[];
  LOs: any;
  closedLoans: any;
  constructor(  private route: ActivatedRoute,
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,) {
      

    }

  ngOnInit() {  
    

  }
  ngAfterViewInit():void {
    this.leaders = []
    this.names = []
    this.closedLoans = []
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.statsRef = this.afs.collection('stats/').valueChanges().subscribe(data => {
          this.stats = data;
          this.stats.forEach(item => {
  this.leaderData = item;
  this.LOs = this.leaderData.firstName;
  this.closedLoans.push(this.leaderData.lead.value)
  this.names.push(this.LOs)
  this.leaders.push(this.leaderData)
          });

    let config = {
      type: "bar",
      data: {
        labels: this.names,
        datasets: [
          {
            // new Date().getFullYear() - 1,
            label: 'Closed Loans',
            fill: 'origin',
            backgroundColor:  "rgba(255, 255, 255, 0.54)",
            borderColor: "white",
            data: this.closedLoans,
            barThickness: 12,
            type:'bar'
          },
          // {
          //   // new Date().getFullYear()
          //   label: 'Lead Pipeline Value',
          //   backgroundColor: "#ed64a6",
          //   borderColor: "#ed64a6",
          //   data: this.leadsValue,
          //   fill: false,
          //   barThickness: 12,
          // },
          
        
       
          // {
          //   // new Date().getFullYear() - 2
          //   label: 'Lead Generated',
          //   fill: false,
          //   backgroundColor: "#39BE0E",
          //   borderColor: "#39BE0E",
          //   data: [this.stat[3].January, this.stat[3].February],
          //   barThickness: 12,
          // },
          // {
          //   // new Date().getFullYear() - 2
          //   label: 'Attempting Contact',
          //   fill: false,
          //   backgroundColor: "#4c5188",
          //   borderColor: "#4c5aa8",
          //   data: [this.stat[0].January, this.stat[0].February],
          //   barThickness: 12,
          // },
          // {
          //   // new Date().getFullYear() - 2
          //   label: 'test num',
          //   fill: false,
          //   backgroundColor: "#4r5188",
          //   borderColor: "#4r5aa8",
          //   data: [ 1, 2 , 5],
          //   barThickness: 12,
          // }
        ],
      },
      options: {
     
        animation: {
          duration: 0
      },
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Orders Chart",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "white",
          },
          align: "end",
          position: "bottom",
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              stacked: true,
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Month",
                fontColor: "white",
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              stacked: true,
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
                fontColor: "white",
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: "rgba(255, 255, 255, 0.15)",
                zeroLineColor: "rgba(33, 37, 41, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    let ctx: any = document.getElementById("bar-chart3") as HTMLCanvasElement;
    ctx = ctx.getContext("2d");
    var newCharty = new Chart(ctx, config);
    newCharty.data.datasets[0].data[0];
    newCharty.update()
    new Chart(ctx, config);

    
    if(typeof ctx.destroy != "undefined") ctx.destroy();
    ctx = document.getElementById('bar-chart3');
    ctx = ctx.getContext("2d");
    var newGraph = new Chart(ctx, config);
    newGraph.update();
    
  })
}})
  }
  addData(chart, label, data) {
    chart.data.labels.push(label);
    chart.data.datasets.forEach((dataset) => {
        dataset.data.push(data);
    });
    chart.update();
}
  async test(){
    await console.log(this.authService.profile.uid,'  DNC = '+ this.dataService.stati.DNC)
  }
}