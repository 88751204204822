
import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { AuthService } from "../../../shared/services/auth.service";
@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
})
export class SettingsComponent implements OnInit {
  constructor(  private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    public authService: AuthService) {
    this.afAuth.authState.subscribe(user => {
      if(user) {
        console.log(user.uid)
      }
    else {
      console.log('no user')
    }})

  }

  ngOnInit(): void {}
}