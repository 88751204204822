import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-future',
  templateUrl: './future.component.html',

})
export class FutureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
