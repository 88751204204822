import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notapproved',
  templateUrl: './notapproved.component.html',
  
})
export class NotapprovedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
