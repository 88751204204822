import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-create-agent',
  templateUrl: './create-agent.component.html',

})
export class CreateAgentComponent implements OnInit {

  replyId: string;
  str: any;
  selfgen: boolean;
  loName: string;
  lo: string[];
  attendedSem: boolean;

  constructor(
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    ) {
      this.loName = 'Agent Contacted';
      this.lo = ['Gave Us A Lead', 'Agent Contacted', 'Attempting Contact']
      this.route.paramMap.subscribe(params => {
        this.replyId = params.get("id")
      })
      this.str = this.replyId.substring(1);
      if (this.str == this.authService?.profile?.uid){
        this.selfgen = true
      }
      else {
        this.selfgen = false
      }
     }

  ngOnInit(): void {
  }
  submitAgent(firstName, lastName, phone, email, city, state,  notes, str, attended, loName, broker, license){
   
    const first = firstName || 'none'
    const last = lastName|| 'none'
    const phoneNum = phone || 'none'
    const emailAddress = email|| 'none'
    const cityName = city|| 'none'
    const stateName = state|| 'none'
    const note = notes|| 'none'
    const LO = str|| 'none'
   const brokerName = broker || 'none'
   const licenseNum = license || 'none'
    const status = loName|| 'none'
    if (attended == 'on'){
      this.attendedSem = true;
    }
    if (attended == 'off'){
      this.attendedSem = false;
    }

    console.log(first, last, phoneNum, emailAddress, cityName, stateName,  note, LO, this.attendedSem, status, brokerName, licenseNum)

  }
}
