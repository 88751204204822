import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-political-capital',
  templateUrl: './political-capital.component.html',
  styleUrls: ['./political-capital.component.css']
})
export class PoliticalCapitalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
