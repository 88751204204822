import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';
import firebase from 'firebase/app'
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-vaclassdistribution',
  templateUrl: './vaclassdistribution.component.html',

})
export class VAClassdistributionComponent implements OnInit {
  loCol: AngularFirestoreCollection<any>;
  lo: any;
  loName: string;
  dtcVABuyersDistRef: AngularFirestoreCollection<any>;
  VAClassRegistrantsDist: any;
  latestDoc: any;
  name = "ng-toggle-button";
  config = {
    value: false,
    name: "",
    disabled: false,
    height: 25,
    width: 125,
    margin: 3,
    fontSize: 10,
    speed: 600,
    color: {
      checked: "#56C128",
      unchecked: "#dcdcdc"
    },
    switchColor: {
      checked: "#3366FF",
      unchecked: "crimson"
    },
    labels: {
      unchecked: "Not Yet Attended",
      checked: "Attended"
    },
    checkedLabel: "",
    uncheckedLabel: "",
    fontColor: {
      checked: "#fafafa",
      unchecked: "#ffffff"
    }
  };
  tranferLo: string;

  constructor(private route: ActivatedRoute,
    public authService: AuthService,
    public dataService: DataService,
    private afStorage: AngularFireStorage,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    public router: Router,
    private httpClient: HttpClient) { 
     this.loadVAConsumers()
      this.loName = 'Choose an LO';
      this.tranferLo = 'Tranfer Lead'
      this.loCol = this.afs.collection('users/',ref=>ref.where('role','==','LO'));
      this.lo = this.loCol.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
  
          return { id, ...data };
        }))
      );
     
    }

  ngOnInit(): void {
  }
  changeLO(id, LO){
this.dataService.convert2(id, LO)
  }
  sendIT(id, LO){
  
    this.dataService.convert(id, LO)
  }
  transfer(id, newLO, oldLO){
  console.log(id, newLO, oldLO)
  }
  didNot(id){
    this.afs.collection('DMSVAConsumers').doc(id).update({
      attended: false
    })
    this.AttendaceUpdate(-1)
  }
  attend(id){
    console.log(id)
    this.afs.collection('DMSVAConsumers').doc(id).update({
      attended: true
    })
    this.AttendaceUpdate(1)
      }
      AttendaceUpdate(upDown){
        const user = this.authService.profile.uid
        const date = new Date();  // 2009-11-10
        // const month = date.toLocaleString('default', { month: 'long' });
        const increment = firebase.firestore.FieldValue.increment(upDown);
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        this.afs.doc('stats/Team/'+ year +'/TeamStats').update({
          totalVAConsumersAttended: increment,
          [month + 'VAConsumersAttended']:increment,
      
         });
      }
      registrantSearch(email){
        this.dtcVABuyersDistRef = this.afs.collection('DMSVAConsumers/', ref => ref.where('array','array-contains',email));
        this.VAClassRegistrantsDist = this.dtcVABuyersDistRef.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            
            return { id, ...data };
          }))
        );
      }
      loadVAConsumers(){
        
        this.dtcVABuyersDistRef = this.afs.collection('DMSVAConsumers/', ref => ref.where('converted','==',false).limit(10).orderBy('createdOn', 'desc').startAfter(this.latestDoc || 1000000000000000000000));
        this.VAClassRegistrantsDist = this.dtcVABuyersDistRef.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            this.latestDoc = data.createdOn
            if (!data.array){
              console.log('set array')
            //  this.logCreation(data.uid, this.loRef)
  
            this.afs.doc('DMSVAConsumers/'+ id).update({
              array: firebase.firestore.FieldValue.arrayUnion(data.first_name || '', data.last_name|| '', data.email|| '', data.phone|| '', data.class|| '')
            })
            }
            this.latestDoc = data.createdOn
            return { id, ...data };
          }))
        );
      }
      timeStamp(time){
        const ms = Date.parse(time);
        console.log(ms)
      }
    
      classDate(person, classDate){
        console.log(person, classDate)
        this.afs.collection('DMSVAConsumers').doc(person).update({
          classDate: classDate
        })
      }
   
}


// July 7th = 1625698800000 
// July 8th = 1625770680000
// July 15th = 1626368400000
// July 14th = 1626310680000
// july 21 = 1626908400000
// July 28 = 1627513200000
// aug 4th = 1628118000000