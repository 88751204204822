import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-dtc-leads',
  templateUrl: './dtc-leads.component.html',
  
})
export class DtcLeadsComponent implements OnInit {
  month: string;
  teamRef: AngularFirestoreCollection<any>;
  team: any;
  monthAttempts: string;

  constructor( public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    private firestore: AngularFirestore) {
      const date = new Date(); 
      this.month = date.toLocaleString('default', { month: 'long' });
    
      this.teamRef = this.afs.collection('users/',ref=>ref.where('role','==','LO').orderBy(this.month + 'contactAttempts','desc'));
      this.team = this.teamRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
  // this.monhtContact = data.
          return { id, ...data };
        }))
      );
     }

  ngOnInit(): void {
  }

}
