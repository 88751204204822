import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";


// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";
import { SecureInnerPagesGuard } from "./shared/guard/secure-inner-pages.guard";
import { AgentSearchComponent } from "./views/admin/agent-search/agent-search.component";
import { AgentcontactedComponent } from "./views/admin/agentcontacted/agentcontacted.component";
import { AgentdncComponent } from "./views/admin/agentdnc/agentdnc.component";
import { AttemptingComponent } from "./views/admin/attempting/attempting.component";
import { ClosedComponent } from "./views/admin/closed/closed.component";
import { ContactComponent } from "./views/admin/contact/contact.component";
import { ContactedComponent } from "./views/admin/contacted/contacted.component";
import { ControlboardComponent } from "./views/admin/controlboard/controlboard.component";
import { CreateAgentComponent } from "./views/admin/create-agent/create-agent.component";
import { CreateComponent } from './views/admin/create/create.component';
import { CreditComponent } from "./views/admin/credit/credit.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { DeadComponent } from "./views/admin/dead/dead.component";
import { DNCComponent } from "./views/admin/dnc/dnc.component";
import { DtcLeadsComponent } from "./views/admin/dtc-leads/dtc-leads.component";
import { FollowupComponent } from "./views/admin/followup/followup.component";
import { FutureComponent } from "./views/admin/future/future.component";
import { LeadSearchComponent } from "./views/admin/lead-search/lead-search.component";
import { LeadComponent } from "./views/admin/lead/lead.component";
import { LeadsComponent } from "./views/admin/leads/leads.component";
import { MapsComponent } from "./views/admin/maps/maps.component";
import { NotapprovedComponent } from "./views/admin/notapproved/notapproved.component";
import { PreapprovedComponent } from "./views/admin/preapproved/preapproved.component";
import { ProcessingComponent } from "./views/admin/processing/processing.component";
import { SentleadsComponent } from "./views/admin/sentleads/sentleads.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { ShoppingComponent } from "./views/admin/shopping/shopping.component";
import { TablesComponent } from "./views/admin/tables/tables.component";
import { UncontactedComponent } from "./views/admin/uncontacted/uncontacted.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";
import { FormComponent } from "./views/form/form.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";
import { ProfileComponent } from "./views/profile/profile.component";
import { TransferComponent } from "./views/transfer/transfer.component";
// import { SecureInnerPagesGuard } from '/shared/guard/secure-inner-pages.guard';

const routes: Routes = [
  // admin views
  {
    path: "admin",
    component: AdminComponent,
    children: [
      { path: "dashboard", component: DashboardComponent,canActivate: [SecureInnerPagesGuard] },
      { path: "control", component: ControlboardComponent,canActivate: [SecureInnerPagesGuard] },
      { path: "settings", component: SettingsComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "tables", component: TablesComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "maps", component: MapsComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "create/:id", component: CreateComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "createAgent/:id", component: CreateAgentComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "leads", component: LeadsComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "closed", component: ClosedComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "processing", component: ProcessingComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "preapproved", component: PreapprovedComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "contacted", component: ContactedComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "agentcontacted", component: AgentcontactedComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "attempting", component: AttemptingComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "agentdnc", component: AgentdncComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "sentleads", component: SentleadsComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "followup", component: FollowupComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "future", component: FutureComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "credit", component: CreditComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "shopping", component: ShoppingComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "DNC", component: DNCComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "dead", component: DeadComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "DTC", component: DtcLeadsComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "uncontacted", component: UncontactedComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "notapproved", component: NotapprovedComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "lead/:id", component: LeadComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "contact/:id", component: ContactComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "leadSearch", component: LeadSearchComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "agentSearch", component: AgentSearchComponent ,canActivate: [SecureInnerPagesGuard]},
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
    ],
  },
  // auth views
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "register", component: RegisterComponent },
      { path: "", redirectTo: "auth/login", pathMatch: "full" },
    ],
  },
  // no layout views
  // { path: "profile", component: ProfileComponent },
  { path: "landing/:id", component: LandingComponent },
  { path: "form/:id", component: FormComponent },
  { path: "transfer/:id", component: TransferComponent },
  // { path: "", component: LoginComponent },
  { path: "**", redirectTo: "auth/login", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
