import { Component, OnInit, AfterViewInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { ActivatedRoute } from "@angular/router";
import Chart from "chart.js";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import { DataService } from "src/app/shared/services/data.service";

@Component({
  selector: 'app-lead-chart',
  templateUrl: './card-lead-chart.component.html',
 
})
export class CardLeadChartComponent implements OnInit {
  stats: any;
  contacted: any[];
  dnc: any[];
  myLeads: any;
  statsRef: any;
  closedValue: any[];
  leads: any[];
  contactedAttempt: any[];
  gal: any[];
  P: any[];
  PA: any[];
  SA: any[];
  CR: any[];
  CFR: any[];
  FO: any[];
  dead: any[];
  NA: any[];
  closed: any[];
  

  constructor( private route: ActivatedRoute,
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,) {}

  ngOnInit() {}
  ngAfterViewInit() {
  // this.notApproved = []
  this.closedValue = []
  this.contacted = []
  this.dnc = []
  this.leads = []
  this.gal = []
  this.P = []
  this.PA = []
  this.SA = []
  this.CR = []
  this.CFR = []
  this.FO = []
  this.dead= []
  this.NA = []
  this.closed = []
  this.contactedAttempt = []
    this.afAuth.authState.subscribe(user => {
      if (user) {
     
    this.statsRef = this.afs.collection('stats/'+ user.uid + '/2021').valueChanges().subscribe(data => {
      this.stats = data;
      this.stats.forEach(item => {
if (item.status == 'Contacted'){
this.contacted.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
// console.log(this.contacted)
}
if (item.status == 'Attempting Contact'){
  this.contactedAttempt.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
  // console.log(this.contacted)
  }
if (item.status == 'DNC'){
  this.dnc.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
  // console.log(this.dnc)
  }
  if (item.status == 'Processing'){
    this.P.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
    // console.log(this.leads)
    }
    if (item.status == 'Pre-Approved'){
      this.PA.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
      // console.log(this.leads)
      }
      if (item.status == 'Contacted - Follow up required'){
        this.CFR.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
        // console.log(this.leads)
        }
        if (item.status == 'Credit Repair'){
          this.CR.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
          // console.log(this.leads)
          }
          if (item.status == 'Dead'){
            this.dead.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
            // console.log(this.leads)
            }
            if (item.status == 'Shopping Around'){
              this.SA.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
              // console.log(this.leads)
              }
              if (item.status == 'Closed'){
                this.closed.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
                // console.log(this.leads)
                }
                if (item.status == 'Not Approved'){
                  this.NA.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
                  // console.log(this.leads)
                  }
                  if (item.status == 'Future Opportunity'){
                    this.FO.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
                    // console.log(this.leads)
                    }
      });
     
    var config = {
      type: "line",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],
        datasets: [
         
          {
            // new Date().getFullYear() - 2
            label: 'Dead',
            fill: false,
            backgroundColor: "#910303",
            borderColor: "#910303",
            data: this.dead,
            barThickness: 24,
            type:'bar'
          },
          {
            // new Date().getFullYear() - 1,
            label: 'DNC',
            fill: false,
            backgroundColor: "#c20404",
            borderColor: "#c20404",
            data: this.dnc,
            barThickness: 24,
            type:'bar'
          },
          
          {
            // new Date().getFullYear() - 1,
            label: 'Not Approved',
            fill: false,
            backgroundColor: "#f70202",
            borderColor: "#f70202",
            data: this.NA,
            barThickness: 24,
            type:'bar'
          },
          {
            // new Date().getFullYear() - 1,
            label: 'Shopping Around',
            fill: false,
            backgroundColor: "#bf6402",
            borderColor: "#bf6402",
            data: this.SA,
            barThickness: 24,
            type:'bar'
          },
          {
            // new Date().getFullYear() - 1,
            label: 'Credit Repair',
            fill: false,
            backgroundColor: "#ff8503",
            borderColor: "#ff8503",
            data: this.CR,
            barThickness: 24,
            type:'bar'
          },
          {
            // new Date().getFullYear() - 1,
            label: 'Future Opportunity',
            fill: false,
            backgroundColor: "#00539c",
            borderColor: "#00539c",
            data: this.FO,
            barThickness: 24,
            type:'bar'
          },
          
          {
            // new Date().getFullYear() - 2
            label: 'Contacted - Folow up required',
            fill: false,
            backgroundColor: "#0268c2",
            borderColor: "#0268c2",
            data: this.CFR,
            barThickness: 24,
            type:'bar'
          },
        
          {
            // new Date().getFullYear()
            label: 'Contacted',
            backgroundColor: "#0389ff",
            borderColor: "#0389ff",
            data: this.contacted,
            fill: false,
            barThickness: 24,
            type:'bar'
          },
          {
            // new Date().getFullYear() - 2
            label: 'Pre-Approved',
           
            backgroundColor: "#0bc30f",
            borderColor: "#0bc30f",
            data: this.PA,
            barThickness: 24,
            type:'bar'
          },
          {
            // new Date().getFullYear() - 2
            label: 'Processing',
           
            backgroundColor: "#0aea0e",
            borderColor: "#0aea0e",
            data: this.P,
            barThickness: 24,
            type:'bar'
          },
        
         
          {
            label: 'Closed',
            fill: 'origin',
            backgroundColor: 'rgba(5, 5, 5, 0.80)',
            borderColor: "#000",
            data: this.closed,
            barThickness: 12,
          
            
          },
          
        ],
      },
      options: {
        animation: {
          duration: 0
      },
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Sales Charts",
          fontColor: "white",
        },
        legend: {
          reverse:false,
          labels: {
            fontColor: "white",
          },
          align: "end",
          position: "bottom",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              stacked: true,
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Month",
                fontColor: "white",
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              stacked: true,
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
                fontColor: "white",
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: "rgba(255, 255, 255, 0.15)",
                zeroLineColor: "rgba(33, 37, 41, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    let ctx: any = document.getElementById("line-chart-2") as HTMLCanvasElement;
    ctx = ctx.getContext("2d");
     var gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, 'rgba(255, 255, 255, 0.44)');   
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0.50)');
    new Chart(ctx, config);
    if(typeof ctx.destroy != "undefined") ctx.destroy();
    ctx = document.getElementById('line-chart-2');
    ctx = ctx.getContext("2d");
    new Chart(ctx, config);

  })

  
}})



  }
 
}
