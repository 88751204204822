import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { DataService } from "../../../shared/services/data.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import Swal from 'sweetalert2';

@Component({
  selector: "app-card-dead",
  templateUrl: "./card-table-dead.component.html",
})
export class CardTableDeadComponent implements OnInit {
  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor(
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
  ) {}

  ngOnInit(): void {}
}
