import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-line-chart3',
  templateUrl: './card-line-chart3.component.html',

})
export class CardLineChart3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
