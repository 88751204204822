import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-controlboard',
  templateUrl: './controlboard.component.html',

})
export class ControlboardComponent implements OnInit {
  now: number;
  classes: any;
  titles: any;
  date: any;
  dates: any;
  attended: any;
  clickAttended: boolean;
  attend: any;
  loading: boolean;

  constructor( public authService: AuthService,
    public dataService: DataService,
    private afStorage: AngularFireStorage,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    public router: Router,
    private httpClient: HttpClient) {
      this.loading = false
    this.getDataFromServer().
    then(res => {
      // console.log('res: ', res);
      this.classes = res;
      this.titles = this.classes.registrations
      console.log(this.titles)
      // this.titles.forEach(element => {
      //   console.log(element.id)
      //   this.updateAttendees(element.id).then(resp => {
      //     console.log(resp)
      //     this.attendees = resp
      //   })
      // });
      
    
    });
    this.getDatesFromServer().
    then(resp => {
      // console.log('res: ', res);
      this.date = resp;
      this.dates = this.date.child_conferences
      console.log(this.dates)
      // this.titles.forEach(element => {
      //   console.log(element.id)
      //   this.updateAttendees(element.id).then(resp => {
      //     console.log(resp)
      //     this.attendees = resp
      //   })
      // });
    });
    
   }

  ngOnInit(): void {
  }
  getDataFromServer() {
    const date = new Date()
    this.now = (Date.now() / 1000) - 1000000;

    const params = {
      'API-KEY': '2f013d448ccd9e79a3e7'
      // param2: value2
    }
    const url = 'https://www.bigmarker.com/api/v1/conferences/registrations_with_fields/2732be54d7f3/?per_page=100'

    // { params: params } is the same as { params } 
    // look for es6 object literal to read more
    return this.httpClient.get(url, { params }).toPromise();
  }

  getDatesFromServer() {
    const date = new Date()
    this.now = (Date.now() / 1000) - 1000000;

    const params = {
      'API-KEY': '2f013d448ccd9e79a3e7'
      // param2: value2
      //DMS open class = 443d32d32451
    }
    // const url = 'https://www.bigmarker.com/api/v1/conferences/448607331de7/attendees'
    const url = 'https://www.bigmarker.com/api/v1/conferences/recurring/443d32d32451?start_time=0&end_time=16482844000&per_page=100'
// const url = 'https://www.bigmarker.com/api/v1/conferences/get_associated_sessions/443d32d32451/?per_page=100'
    // { params: params } is the same as { params } 
    // look for es6 object literal to read more
    return this.httpClient.get(url, { params }).toPromise();
  }
  getAttendeesFromServer(id) {
    const date = new Date()
    this.now = (Date.now() / 1000) - 1000000;

    const params = {
      'API-KEY': '2f013d448ccd9e79a3e7'
      // param2: value2
      //DMS open class = 443d32d32451
    }
    // const url = 'https://www.bigmarker.com/api/v1/conferences/448607331de7/attendees'
const url = 'https://www.bigmarker.com/api/v1/conferences/' + id +'/attendees'
    // { params: params } is the same as { params } 
    // look for es6 object literal to read more
    return this.httpClient.get(url, { params }).toPromise();
  }
  getBMIDFromServer(id) {
    const date = new Date()
    this.now = (Date.now() / 1000) - 1000000;

    const params = {
      'API-KEY': '2f013d448ccd9e79a3e7'
      // param2: value2
      //DMS open class = 443d32d32451
    }
    // const url = 'https://www.bigmarker.com/api/v1/conferences/448607331de7/attendees'
// const url = 'https://www.bigmarker.com/api/v1/conferences/' + id +'/stats?bmid=xxx''
    // { params: params } is the same as { params } 
    // look for es6 object literal to read more
    // return this.httpClient.get(url, { params }).toPromise();
  }
  attendees(id){
    this.loading = true
    this.clickAttended = true
    this.getAttendeesFromServer(id).
    then(response => {
      // console.log('res: ', res);
      this.loading = false
      this.attended = response;
      this.attend = this.attended.attendees
      console.log(this.attended)
      // this.titles.forEach(element => {
      //   console.log(element.id)
      //   this.updateAttendees(element.id).then(resp => {
      //     console.log(resp)
      //     this.attendees = resp
      //   })
      // });
    });
  }



  add(first_name,last_name, email, phone, classdate, state){
console.log(first_name,last_name, email, phone, classdate, state)
this.now = Date.now()
const ms = Date.parse(classdate);
console.log(ms);
const classDate = ms;
this.afs.collection('DMSVAConsumers/').doc().set({
  first_name: first_name,
  last_name: last_name,
  classId: 'DMSAgents',
  phone: phone ,
  email: email,
  registered: true,
  attended: false,
  LO: 'open',

  createdOn: this.now,
  contacted: false,
  converted: false,

  state: state ,
  classDate: classDate
},{merge: true})
  }
}
