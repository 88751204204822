import { Component, OnInit} from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AngularFireFunctions } from '@angular/fire/functions';
import firebase from 'firebase/app'

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',

})
export class TransferComponent implements OnInit {
  replyId: string;
  str: any;

  leadInfo: any;
  leadData: any;
  loCol: AngularFirestoreCollection<any>;
  lo: any;
  loName: string;
  confirmation: any;
  partnerEmail: any;
  created: any;
  confirmation2: any;
  now: number;
  ts: Date;

  constructor(private route: ActivatedRoute,
    public authService: AuthService,
    public dataService: DataService,
    private afStorage: AngularFireStorage,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    public router: Router,) {
      this.ts = new Date();
      this.created = this.ts.toDateString();
      // this.created is the pretty time
      this.now = Date.now();
      const date = new Date();
    Window["myComponent"] = this;
    this.loName = 'Choose an LO';
    this.route.paramMap.subscribe(params => {
      this.replyId = params.get("id")
    })
    this.str = this.replyId.substring(1);
  
    this.leadInfo = this.afs.doc('referrals/' + this.str).valueChanges().subscribe(data => {
      this.leadData = data
    })
    this.loCol = this.afs.collection('users', ref => ref.where('role', '==', 'LO'));
    this.lo = this.loCol.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;

        return { id, ...data };
      }))
    );
    // this.dataService.loadAgentLeads(this.str)
  }

  ngOnInit() {}
  


  async changeLO(name, phone, email, status, id) {
    this.dataService.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Lead Transfered', 'Lead Transfered to ' + name + ' from ' + this.authService.profile.firstName, 'fas fa-arrows-alt-h', 'bg-blue-600', this.str)
    this.dataService.logAction(id, this.authService.profile.firstName, this.authService.profile.lastName, 'Lead Transfered', 'Lead Transfered to ' + name + ' from ' + this.authService.profile.firstName, 'fas fa-arrows-alt-h', 'bg-blue-600', this.str)
    console.log(name, phone, email, this.leadData)
    Swal.fire(
      'LO Saved',
      'Text and email sent to ' + name + ' ' + phone + ' ' + email,
      'success'
    )
    //update lead
this.afs.doc('referrals/'+ this.str).update({
LO: id,
LOEmail:email
}).then();{
  if (status == 'New') {
    this.dataService.newLeadNotification(this.authService.profile.uid, -1)
    

  }
  //redirect
  this.router.navigate(['admin/dashboard']);
//update stats...  Add to new LO and remove from old LO

  this.dataService.decrementLeadStatus(this.authService.profile.uid, status, -this.leadData.loanAmount) 
  this.dataService.leadStatUpdate(id, status, this.leadData.loanAmount) 
  this.dataService.leadStatUpdate(this.authService.profile.uid, 'Lead', -this.leadData.loanAmount)
}    if (status == 'Closed') {
  const increment = firebase.firestore.FieldValue.increment(1);
  const incrementValue = firebase.firestore.FieldValue.increment(this.leadData.loanAmount);
  this.afs.doc('users/'+ id).update({
   closedCount:increment,
   closedValue: incrementValue
    })
}
    if (status == 'New') {
      const increment = firebase.firestore.FieldValue.increment(1);
    
      this.afs.doc('users/'+ id).update({
       newLeadCount:increment,
       
        })
      console.log(status)
    }
    else {
      console.log(status)
    }
    const fun = this.functions.httpsCallable('transferEmail');
    this.confirmation = await fun({
      dest: email,
      readableTime: this.created,
      email: this.leadData.email,
      firstName: this.leadData.firstName,
      lastName: this.leadData.lastName,
      phone: this.leadData.phone,
      state: this.leadData.state,
      loan: this.leadData.loanAmount,
      notes: this.leadData.notes,
      link:'https://dashboard.thevaloan.org/admin/lead/:'+this.str
    }).toPromise();

    const fun2 = this.functions.httpsCallable('transferText');
    this.confirmation2 = await fun2({
      dest: '+1' + phone,
      readableTime: this.created,
      email: this.leadData.email,
      firstName: this.leadData.firstName,
      lastName: this.leadData.lastName,
      phone: this.leadData.phone,
      state: this.leadData.stateName,
      loan: this.leadData.loan,
      notes: this.leadData.notes,
      link:'https://dashboard.thevaloan.org/admin/lead/:'+this.str
    }).toPromise();
    return [fun, fun2];
  }
}
