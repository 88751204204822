import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
 
})
export class LeadComponent implements OnInit {
  replyId: string;
  str: any;

  constructor(private route: ActivatedRoute,
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,) { 
      {
        this.route.paramMap.subscribe(params => {
          this.replyId = params.get("id")
        })
        console.log(this.replyId)
        this.str = this.replyId.substring(1);
        console.log( this.str);
        this.dataService.loadLead(this.str)
       }
    }

  ngOnInit(): void {
  }

}
