import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
// import { AuthService } from 'src/app/shared/services/auth.service';
// import { DataService } from 'src/app/shared/services/data.service';
import { FormBuilder, Validators } from "@angular/forms";
import { AngularFireFunctions } from '@angular/fire/functions';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',

})
export class FormComponent implements OnInit {
  replyId: string;
  str: any;


State: any = [
  'AL','AK','AS','AZ','AR','CA','CO','CT','DE','DC','FM','FL','GA',
  'GU','HI','ID','IL','IN','IA','KS','KY','LA','ME','MH','MD','MA',
  'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
  'MP','OH','OK','OR','PW','PA','PR','RI','SC','SD','TN','TX','UT',
  'VT','VI','VA','WA','WV','WI','WY'
 ];
isSubmitted = false;
  stateName: string;
  state: '';
  ts: Date;
  created: any;
  now: number;
  isChecked: boolean;

 
  confirmation: any;
  agentForm: any;
  partner: any;
  partnerEmail: any;
  confirmation2: any;
  registrationForm: any;

  constructor(private route: ActivatedRoute,
    // public authService: AuthService, 
    // public dataService: DataService,
    private functions: AngularFireFunctions,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
    public fb: FormBuilder) { 
      this.ts = new Date();
      this.isChecked = true;
      // this.now is the timeStamp
      this.created = this.ts.toDateString();
      // this.created is the pretty time
      this.now = Date.now();
      const date = new Date();
 this.stateName ='Choose your State';
      this.route.paramMap.subscribe(params => {
        this.replyId = params.get("id")
      })
      this.str = this.replyId.substring(1);
      // this.dataService.loadAgentLeads(this.str)
     }

  ngOnInit(): void {
    this.myFormValues();
  }
  onSubmit() {
    this.isSubmitted = true;
    if (!this.registrationForm.valid) {
      alert('Form is valid')
      return false;
    } else {
      alert('Form not valid')
      console.log(this.registrationForm)
    }
  }
  myFormValues(){
    this.registrationForm = this.fb.group({
      stateName: ['', [Validators.required]],
      notes: ['', [Validators.required]],
      military:['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone: ['', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
      loan: ['', [Validators.required]],
    })
  }
  stateValue(state, firstName, lastName, phone, email, notes,  military, loan, str){

    this.isSubmitted = true;
    if (!this.registrationForm.valid) {
      console.log('Please fill out all fields')
      return false;
    } else {
      console.log(state, firstName, lastName, phone, email, notes,  military, loan, str, 'posted') 
      this.formSubmit(state, firstName, lastName, phone, email, notes,  military, loan, str)
      this.myFormValues();
  this.registrationForm.reset(this.registrationForm.value)
    }
   
  
    
    
  }
  formSubmit(stateName, firstName, lastName, phone, email, notes,  military, loan, id){
    
     console.log(id)
    // console.log(firstName, lastName, phone, email, address, city, state, zip, futureAddress, futureCity, futureState, futureZip, notes)
    this.afs.collection('formLeads').add({
      firstName:firstName, 
      lastName:lastName, 
      phone:phone, 
      email:email, 
      notes:notes,
      owner:id,
      state:stateName,    
      dateCreated: this.now,
      date:this.created,
      loanAmount: loan,
      isMilitary: military,
      status: 'New',
      LO: 'open'
    }).then(() => {
      this.agentForm = this.afs.doc('websiteFormPartners/' + id).valueChanges().subscribe( async data => {
        this.partner = data
       this.partnerEmail = this.partner.email
       console.log(this.partnerEmail)
      
    
       const fun = this.functions.httpsCallable('leadFormEmail');
       this.confirmation =  await fun({
         dest: this.partnerEmail, 
         readableTime: this.created,
     email: email,
     firstName: firstName,
     lastName: lastName,
     phone: phone,
     state: stateName,
     loan: loan,
     notes: notes 
       }).toPromise();
         
       const fun2 = this.functions.httpsCallable('formText');
       this.confirmation2 =  await fun2({
         dest: this.partnerEmail, 
         readableTime: this.created,
     email: email,
     firstName: firstName,
     lastName: lastName,
     phone: phone,
     state: stateName,
     loan: loan,
     notes: notes 
       }).toPromise();
       return [fun ,fun2];
      })
     
      this.registrationForm.markAsPristine();
      this.registrationForm.markAsUntouched();
      Swal.fire(
        'Submitted!',
        'Your form has been submitted, someone from the Mutual Of Omaha team will be in touch shortly!',
        'success'
      )
    })}
 
      // this.agentUpdateCounter(this.authService.profile.uid, loan)
      // this.totalFormLeadCounter(this.authService.profile.uid, loan)
      // this.leadFormStatUpdate(this.authService.profile.uid, 'Lead', loan )
      // this.leadFormStatUpdate(this.authService.profile.uid, 'New', loan )
  
  changeState(e) {
   console.log(e)
  }
}
