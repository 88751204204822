import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-vaclass-contacted',
  templateUrl: './vaclass-contacted.component.html',

})
export class VAClassContactedComponent implements OnInit {
  month: string;
  teamRef: AngularFirestoreCollection<any>;
  team: any;


  constructor(private route: ActivatedRoute,
    public authService: AuthService,
    public dataService: DataService,
    private afStorage: AngularFireStorage,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    public router: Router,) { 
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });

  }

  ngOnInit(): void {
  }

}
