import { Injectable, NgZone } from '@angular/core';


import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { stringify } from 'querystring';
import firebase from 'firebase/app'
import { Observable, of, Subscription } from 'rxjs';
import { switchMap} from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { Client as ConversationsClient } from "@twilio/conversations"; 

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  messages: Object;
  confirmation: Subscription;

  constructor(public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,  
    public ngZone: NgZone,
    private http: HttpClient,
    private httpClient: HttpClient,
    private functions: AngularFireFunctions,
    // public conversations: ConversationsClient,
    ) { 
     
   
    }

    async message(){
console.log('getting messages')

    // const fun = this.functions.httpsCallable('twilioMessages');
    // this.confirmation =  await fun({
    //   to: +18324391984,
    //   from: +17033825626
  
    // }).toPromise();
    // console.log(this.confirmation)

               
 
      const params = {
        // 'API-KEY': '2f013d448ccd9e79a3e7'
        // param2: value2
           to: '+18324391984',
      from: '+17033825626'
      }
      const url = 'https://us-central1-thevaloan-6fe1e.cloudfunctions.net/twilioMessages'
  
      // { params: params } is the same as { params } 
      // look for es6 object literal to read more
     const getData =  this.httpClient.get(url, { params }).toPromise();
    
    return getData
     .then(res => {
      console.log('res: ', res);
      this.messages = res;
      
    });
    
      }
      async getToken(){
        const fun = this.functions.httpsCallable('twilioConversation');
        this.confirmation =  await fun({}).subscribe(val => console.log(val));
        console.log(this.confirmation) 
      }
//       conversation(){
//         const accountSid = 'ACe3510e20210f92520aa1a7e5af8adc59';
// const authToken = 'a6864da71bba019598d3fbad929dc5aa';
// //         const accountSid = process.env.TWILIO_ACCOUNT_SID;
// // const authToken = process.env.TWILIO_AUTH_TOKEN;
// const client = require('twilio')(accountSid, authToken);

// client.conversations.conversations
//                     .create({friendlyName: 'My First Conversation'})
//                     .then(conversation => console.log(conversation.sid));
//       }
   
}
