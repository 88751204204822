import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { createPopper } from '@popperjs/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-card-leadinfo',
  templateUrl: './card-table-leadinfo.component.html',

})
export class CardTableLeadinfoComponent implements OnInit {
  replyId: string;
  str: any;
  dropdownPopoverShow = false;
  @ViewChild('btnDropdownRef',{ static: false }) btnDropdownRef:ElementRef;
  popper = document.createElement("div");
  leadData: any;
  leadDataSet: any;
  LOData: any;
  LODataSet: any;
  constructor(private route: ActivatedRoute,
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,) { 
      Window["myComponent"] = this;
      
      this.route.paramMap.subscribe(params => {
        this.replyId = params.get("id")
      })
      this.str = this.replyId.substring(1);
      Window["str"] = this.str;
      this.dataService.loadAgentLeads(this.str)
      // this.agentRef = this.afs.collection('users/', ref => ref.where('owner', '==', this.str)).valueChanges().subscribe(data =>{
      //   this.agentData = data
 
      this.leadData = this.afs.doc('referrals/'+ this.str).valueChanges().subscribe(data => {
        this.leadDataSet = data;
  
        this.LOData = this.afs.doc('users/'+ this.leadDataSet.LO).valueChanges().subscribe(data => {
          this.LODataSet = data;
          
         })
        
       })
      // })
    }

    ngOnInit() {                                        
      this.popper.innerHTML = `<div class=" bg-gray-200 text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48" #popoverDropdownRef>
    <a onclick="Window.myComponent.dataService.editLeadData('First Name', 'firstName', Window.str)" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800">
   First Name
    </a>
    <a onclick="Window.myComponent.dataService.editLeadData('Last Name', 'lastName', Window.str)" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800">
      Last Name
    </a>
    <a onclick="Window.myComponent.dataService.editLeadData('Email', 'email', Window.str)" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800">
      Email
    </a>
    <a onclick="Window.myComponent.dataService.editLeadData('Phone Number', 'phone', Window.str)" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800">
    Phone Number
  </a>
  <a onclick="Window.myComponent.dataService.editLeadData('Address', 'address', Window.str)" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800">
  Address
</a>
<a onclick="Window.myComponent.dataService.editLeadData('State', 'state', Window.str)" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800">
State
</a>
<a onclick="Window.myComponent.dataService.editLeadData('Postal Code', 'zip', Window.str)" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800">
Postal Code
</a>
<a onclick="Window.myComponent.dataService.editLeadData('Future Address', 'futureAddress', Window.str)" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800">
Future Address
</a>
<a onclick="Window.myComponent.dataService.editLeadData('Notes', 'notes', Window.str)" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800">
Notes
</a>

    <div class="h-0 my-2 border border-solid border-t-0 border-gray-900 opacity-25"></div>
    <a onclick="Window.myComponent.dataService.editLoanData('Loan Amount', 'loanAmount', Window.str)" class="text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800">
      Loan Amount
    </a>
  </div>`;
    }

    toggleDropdown(){
      if(this.dropdownPopoverShow){ 
        this.dropdownPopoverShow = false;
        this.destroyPopper();
      } else {
        this.dropdownPopoverShow = true;
        this.createPoppper();
      }
    }
    destroyPopper(){
      this.popper.parentNode.removeChild(this.popper);
    }
    createPoppper(){
      createPopper(this.btnDropdownRef.nativeElement, this.popper, {
        placement: "left-start"
      });
      this.btnDropdownRef.nativeElement.parentNode.insertBefore(this.popper, this.btnDropdownRef.nativeElement.nextSibling);
  
    }
    editLeadData(element){
console.log('clicked' + element)
    }
  }
