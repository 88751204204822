import { Component, OnInit} from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AngularFireFunctions } from '@angular/fire/functions';
import firebase from 'firebase/app'


@Component({
  selector: 'app-incomingleads',
  templateUrl: './card-incomingleads.component.html',
  
})
export class CardIncomingleadsComponent implements OnInit {
  ts: Date;
  created: any;
  now: number;
 
  confirmation: any;
  confirmation2: any;
  loCol: AngularFirestoreCollection<any>;
  lo: any;
  openLeadsCol: AngularFirestoreCollection<any>;
  openLeadList: any;
  latestDoc: any;

  constructor(private route: ActivatedRoute,
    public authService: AuthService,
    public dataService: DataService,
    private afStorage: AngularFireStorage,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private functions: AngularFireFunctions,
    public router: Router,) {
    this.latestDoc = null || 0;
    this.moreLeads()
   }

  ngOnInit(): void {
  }
 
  transfer(id){
    this.router.navigate(['/transfer/:'+ id]);
  }
  goToLead(id){
    this.router.navigate(['/admin/lead/:' + id]);
  }
  delete(id){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Lead has been deleted.',
          'success'
        )
        this.afs.doc('referrals/'+ id).delete()
      }
    })


  }
  moreLeads(){
    this.openLeadsCol = this.afs.collection('referrals', ref => ref.where('LO','==','open').limit(10).orderBy('exactTime','desc').startAfter(this.latestDoc || 1000000000000000));
    this.openLeadList = this.openLeadsCol.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        if (!data.array){
          console.log('set array')
        //  this.logCreation(data.uid, this.loRef)

        this.afs.doc('referrals/'+ id).update({
          array: firebase.firestore.FieldValue.arrayUnion(data.firstName || '', data.lastName|| '', data.email|| '', data.phone|| '', data.class|| '', data.state|| '')
        })
        }
this.latestDoc = data.exactTime
        return { id, ...data };
      }))
    );
  }
  
}
