import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-card-team-actions',
  templateUrl: './card-team-actions.component.html',

})
export class CardTeamActionsComponent implements OnInit {

  constructor(  public authService: AuthService,
    public dataService: DataService,) { }

  ngOnInit(): void {
  }

}
