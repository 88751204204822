import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-card-history',
  templateUrl: './card-history.component.html',
 
})
export class CardHistoryComponent implements OnInit {
  replyId: string;
  str: any;
  passedId: string;
  expandActionsRef: AngularFirestoreCollection<any>;
  expandActions: any;
  color:any
  loadActionsRef: AngularFirestoreCollection<any>;
  loadActions: any;
  loadAll: boolean;
  teamData: any;
  teamMemberData: any[];
  agentData: any;
  agentDataSet: any;
 
  constructor(
    private route: ActivatedRoute,
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,
  ) { 
    this.loadAll = false
    this.route.paramMap.subscribe(params => {
      this.replyId = params.get("id")
    })
    this.str = this.replyId.substring(1);
    this.agentData = this.afs.doc('users/'+ this.str).valueChanges().subscribe(data => {
      this.agentDataSet = data;
      
     })

    this.expandActionsRef = this.afs.collection('actions', ref=> ref.where('id','==', this.str ).orderBy('dateCreated', 'desc').limit(5));
    this.expandActions = this.expandActionsRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id; 
      
        return { id, ...data };
      }))
    );
  
  }

  ngOnInit(): void {
  }
  loadAllHistory(){
    this.loadAll = true;
    this.loadActionsRef = this.afs.collection('actions', ref=> ref.where('id','==', this.str ).orderBy('dateCreated', 'desc'));
    this.loadActions = this.loadActionsRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id; 
      
        return { id, ...data };
      }))
    );
  }
}
