
import { Component, OnInit, AfterViewInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { ActivatedRoute } from "@angular/router";
import Chart from "chart.js";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import { DataService } from "src/app/shared/services/data.service";

@Component({
  selector: 'app-card-chart2',
  templateUrl: './card-line-chart2.component.html',
 
})
export class CardLineChart2Component implements OnInit {
 
  stats: any;
  contacted: any[];
  dnc: any[];
  myLeads: any;
  statsRef: any;
  closedValue: any[];
  leads: any[];
  contactedAttempt: any[];
  gal: any[];
  arr: any[];
  agentContacted: any;
  attemptingContact: any;
  dncAgent: any;
  agentLeads: any;
  gaveLead: any;
  

  constructor( private route: ActivatedRoute,
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,) {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.arr = []
  // this.notApproved = []
  this.closedValue = []
  this.contacted = []
  this.dnc = []
  this.leads = []
  this.gal = []
  this.contactedAttempt = []
    this.afAuth.authState.subscribe(user => {
      if (user) {
     
    this.statsRef = this.afs.collection('stats/'+ user.uid + '/2021').valueChanges().subscribe(data => {
    
      this.stats = data;
      this.stats.forEach(item => {
if (item.status == 'Agent Contacted'){
this.contacted.push(item.total)
this.agentContacted = item.total
this.arr.push(item.total)
// console.log(this.contacted)
}
if (item.status == 'Attempting Contact'){
  this.contactedAttempt.push(item.total)
  this.attemptingContact = item.total;

  // console.log(this.contacted)
  }
if (item.status == 'DNC Agent'){
  this.dnc.push(item.total)
  this.dncAgent = item.total;

  // console.log(this.dnc)
  }
  // if (item.status == 'Lead'){
  //   this.leads.push(item.total)
  //   this.agentLeads = item.total;
  
  //   // console.log(this.leads)
  //   }
    if (item.status == 'Gave Us A Lead'){
      this.gal.push(item.total)
      this.gaveLead = item.total;
    
      // console.log(this.leads)
      }
      });
     
    var config = {
      type: "bar",
      data: {
        labels: [
          "Gave Us A Lead",
          "Agent Contacted",
          "Attempting Contact",
          "DNC Agent",
          ,
        
        ],
        datasets: [
         
       
          {
            // new Date().getFullYear() - 1,
            label: '',
            fill: false, 
            backgroundColor: ["green",   "#0F93D1","orange", "#D1230F"],
            // borderColor: "#D1230F",
            data: [this.gaveLead,this.agentContacted,this.attemptingContact, this.dncAgent],
            barThickness: 75,
            type:'bar'
          },
          // {
          //   // new Date().getFullYear() - 2
          //   label: 'Attempting Contact',
          //   fill: false,
          //   backgroundColor: "orange",
          //   borderColor: "orange",
          //   data: [this.attemptingContact],
          //   barThickness: 24,
          //   type:'bar'
          // },
          // {
          //   // new Date().getFullYear()
          //   label: 'Contacted',
          //   backgroundColor: "#0F93D1",
          //   borderColor: "#0F93D1",
          //   data: [this.agentContacted] ,
          //   fill: false,
          //   barThickness: 24,
          //   type:'bar'
          // },
          // {
          //   // new Date().getFullYear() - 2
          //   label: 'Agents with leads',
          //   fill: false,
          //   backgroundColor: "green",
          //   borderColor: "green",
          //   data: [this.gaveLead],
          //   barThickness: 24,
          //   type:'bar'
          // },
         
          
          // {
          //   // new Date().getFullYear() - 2
          //   label: 'Open Leads',
          //   fill: false,
          //   backgroundColor: "#fff",
          //   borderColor: "#fff",
          //   data: this.leads,
          //   barThickness: 12,
           
            
          // },
        ],
      },
      options: {
        animation: {
          duration: 0
      },
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Sales Charts",
          fontColor: "white",
        },
        legend: {
          display: false,
          reverse:false,
          labels: {
            fontColor: "white",
          },
          align: "end",
          position: "bottom",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              stacked: false,
              display: true,
              scaleLabel: {
                display: false,
                // labelString: "Month",
                fontColor: "white",
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              stacked: false,
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
                fontColor: "white",
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: "rgba(255, 255, 255, 0.15)",
                zeroLineColor: "rgba(33, 37, 41, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    //   data: {
    //     labels:  ["Gave Us A Lead",
    //         "Agent Contacted",
    //         "Attempting Contact",
    //         "DNC Agent",
    //         "Leads Generated"],
    //     datasets: [{
    //         // label: '# of Votes',
    //         data: [this.gaveLead, this.agentContacted, this.contactedAttempt, this.dncAgent, this.agentLeads],
    //         backgroundColor: [
    //             'rgba(255, 99, 132, 0.2)',
    //             'rgba(54, 162, 235, 0.2)',
    //             'rgba(255, 206, 86, 0.2)',
    //             'rgba(75, 192, 192, 0.2)',
    //             'rgba(153, 102, 255, 0.2)',
    //             'rgba(255, 159, 64, 0.2)'
    //         ],
    //         borderColor: [
    //             'rgba(255, 99, 132, 1)',
    //             'rgba(54, 162, 235, 1)',
    //             'rgba(255, 206, 86, 1)',
    //             'rgba(75, 192, 192, 1)',
    //             'rgba(153, 102, 255, 1)',
    //             'rgba(255, 159, 64, 1)'
    //         ],
    //         borderWidth: 1
    //     }]
    // },
    // options: {
    //     scales: {
    //         yAxes: [{
    //             ticks: {
    //                 beginAtZero: true
    //             }
    //         }]
    //     }
    // }
    };
    let ctx: any = document.getElementById("line-chart-4") as HTMLCanvasElement;
    var newCharty = new Chart(ctx, config);
    newCharty.data.datasets[0].data[0];

  newCharty.update()
  
    ctx = ctx.getContext("2d");
     var gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, 'rgba(255, 255, 255, 0.44)');   
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0.50)');
    
    
    if(typeof ctx.destroy != "undefined") ctx.destroy();
    ctx = document.getElementById('line-chart-4');
    ctx = ctx.getContext("2d");
    new Chart(ctx, config);
   
  })

  
}})



  }
 
}
