import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-closed',
  templateUrl: './closed.component.html',
 
})
export class ClosedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
