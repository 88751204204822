import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-card-leadhistory',
  templateUrl: './card-table-leadhistory.component.html',
 
})
export class CardTableLeadhistoryComponent implements OnInit {
  replyId: string;
  str: any;
  expandActionsRef: AngularFirestoreCollection<any>;
  expandActions: any;
color:any
  otherActionsRef: AngularFirestoreCollection<any>;
  otherActions: any;
  loadAll: boolean;
  constructor(private route: ActivatedRoute,
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,) { 
      this.route.paramMap.subscribe(params => {
        this.replyId = params.get("id")
      })
      this.str = this.replyId.substring(1);
      this.loadAll = false
      this.expandActionsRef = this.afs.collection('actions', ref=> ref.where('id','==', this.str ).orderBy('dateCreated', 'desc').limit(5));
      this.expandActions = this.expandActionsRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; 
        
          return { id, ...data };
        }))
      );
    }

  ngOnInit(): void {
  }
expandAll(){
  console.log('expand')
  this.loadAll = true
  this.otherActionsRef = this.afs.collection('actions', ref=> ref.where('id','==', this.str ).orderBy('dateCreated', 'desc'));
  this.otherActions = this.otherActionsRef.snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data();
      const id = a.payload.doc.id; 
    
      return { id, ...data };
    }))
  );
}
}
