import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-lead',
  templateUrl: './edit-lead.component.html',
  styleUrls: ['./edit-lead.component.css']
})
export class EditLeadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
