import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DataService } from 'src/app/shared/services/data.service';
import firebase from 'firebase/app'
import { map } from 'rxjs/operators';



@Component({
  selector: 'app-card-team',
  templateUrl: './card-team.component.html',

})
export class CardTeamComponent implements OnInit {
  teamMemberRef: Promise<void>;
  DougRef: AngularFirestoreCollection<any>;Johnson: any;
  Ellis: any;
  Byron: any;
  teamRef: AngularFirestoreCollection<any>;
  team: any;


  month: string;

  EllisRef: AngularFirestoreCollection<any>;
  ellisProcessing: any;
  ellisPreApproved: any;
  ellisContacted: any;
  ellisFollowUp: any;
  ellisfuture: any;
  ellisCredit: any;
  ellisShopping: any;
  DuhonRef: AngularFirestoreCollection<any>;
  Duhon: any;
  duhonProcessing: any;
  duhonPreApproved: any;
  duhonContacted: any;
  duhonFollowUp: any;
  duhonfuture: any;
  duhonCredit: any;
  duhonShopping: any;
  duhonStats: any;
  duhonStat: any[];
  johnsonStats: any;
  johnsonStat: any[];
  johnsonProcessing: any;
  johnsonPreApproved: any;
  johnsonContacted: any;
  johnsonFollowUp: any;
  johnsonfuture: any;
  johnsonCredit: any;
  johnsonShopping: any;
  byronStats: any;
  byronStat: any[];
  byronProcessing: any;
  byronPreApproved: any;
  byronContacted: any;
  byronFollowUp: any;
  byronfuture: any;
  byronCredit: any;
  byronShopping: any;
  robinStats: any;
  robinStat: any[];
  robinProcessing: any;
  robinPreApproved: any;
  robinContacted: any;
  robinFollowUp: any;
  robinfuture: any;
  robinCredit: any;
  robinShopping: any;
  whitneyStats: any;
  whitneyStat: any[];
  whitneyProcessing: any;
  whitneyPreApproved: any;
  whitneyContacted: any;
  whitneyFollowUp: any;
  whitneyfuture: any;
  whitneyCredit: any;
  whitneyShopping: any;
  dougStats: any;
  dougStat: any[];
  dougProcessing: any;
  dougPreApproved: any;
  dougContacted: any;
  dougFollowUp: any;
  dougfuture: any;
  dougCredit: any;
  dougShopping: any;
  travisContacted: any;
  travisStats: any;
  travisStat: any[];
  travisProcessing: any;
  travisPreApproved: any;
  travisFollowUp: any;
  travisfuture: any;
  travisCredit: any;
  travisShopping: any;

;
  Doug: any;
 

  constructor(  public authService: AuthService,
    public dataService: DataService,public afs: AngularFirestore, ) { 
      const date = new Date();  // 2009-11-10
      // const month = date.toLocaleString('default', { month: 'long' });
      const month = date.toLocaleString('default', { month: 'long' });
      this.month = month
      const year = date.getFullYear();
//       this.teamRef = this.afs.collection('stats/');
//       this.team = this.teamRef.snapshotChanges().pipe(
//         map(actions => actions.map(a => {
//           const data = a.payload.doc.data();
//           const id = a.payload.doc.id;
//           const num = '.2021.Lead.status'
// console.log(data)



//           return { id, ...data };
//         }))
//       );

      const db = firebase.firestore();
      this.teamMemberRef = db.collection('users/').where('role', '==', 'LO').get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const docData = doc.data()
if(docData.email == 'dsea@mutualmortgage.com'){
console.log('deSea', docData.email)
this.loaddoug(doc.id)
}
if(docData.email == 'matthewjohnson@mutualmortgage.com'){
  console.log('Johnson', docData.email)
  this.loadjohnson(doc.id)
  }
if(docData.email == 'sellis@mutualmortgage.com'){
  console.log('Ellis', docData.email)
  this.loadEllis(doc.id)
  }
if(docData.email == 'bbrown@mutualmortgage.com'){
  console.log('Byron', docData.email)
  this.loadbyron(doc.id)
  }
  if(docData.email == 'rjohnson@mutualmortgage.com'){
    console.log('Robin', docData.email)
    this.loadrobin(doc.id)
    }
    if(docData.email == 'tcarmack@mutualmortgage.com'){
      console.log('travis', docData.email)
      this.loadtravis(doc.id)
      }
      if(docData.email == 'wschmidt@mutualmortgage.com'){
        console.log('travis', docData.email)
        this.loadwhitney(doc.id)
        }
         
        });
    
    });

    }
  loaddoug(id){

    console.log('Loading doug' , id)
     const date = new Date();
     const year = date.getFullYear();
     const month = date.toLocaleString('default', { month: 'long' });
     this.dougStats = this.afs.collection('stats/' + id + '/'+year).valueChanges().subscribe(data => {
      this.dougStat = data;

      this.dougStat.forEach(item => {

        if(item.status == 'Processing'){
          console.log(item)
          this.dougProcessing = item
          console.log(this.dougProcessing)
           }
           if(item.status == 'Pre-Approved'){
            console.log(item)
            this.dougPreApproved = item
             }
             if(item.status == 'Contacted'){
              console.log(item)
              this.dougContacted = item
               }
               if(item.status == 'Contacted - Follow up required'){
                console.log(item)
                this.dougFollowUp = item
                 }
                 if(item.status == 'Future Opportunity'){
                  console.log(item)
                  this.dougfuture = item
                   }
                   if(item.status == 'Credit Repair'){
                    console.log(item)
                    this.dougCredit = item
                     }
                     if(item.status == 'Shopping Around'){
                      console.log(item)
                      this.dougShopping = item
                       }
                      
      })})
   
    }
   loadjohnson(id){

    console.log('Loading johnson' , id)
     const date = new Date();
     const year = date.getFullYear();
     const month = date.toLocaleString('default', { month: 'long' });
     this.johnsonStats = this.afs.collection('stats/' + id + '/'+year).valueChanges().subscribe(data => {
      this.johnsonStat = data;

      this.johnsonStat.forEach(item => {

        if(item.status == 'Processing'){
          console.log(item)
          this.johnsonProcessing = item
          console.log(this.johnsonProcessing)
           }
           if(item.status == 'Pre-Approved'){
            console.log(item)
            this.johnsonPreApproved = item
             }
             if(item.status == 'Contacted'){
              console.log(item)
              this.johnsonContacted = item
               }
               if(item.status == 'Contacted - Follow up required'){
                console.log(item)
                this.johnsonFollowUp = item
                 }
                 if(item.status == 'Future Opportunity'){
                  console.log(item)
                  this.johnsonfuture = item
                   }
                   if(item.status == 'Credit Repair'){
                    console.log(item)
                    this.johnsonCredit = item
                     }
                     if(item.status == 'Shopping Around'){
                      console.log(item)
                      this.johnsonShopping = item
                       }
                      
      })})
   
    }
    loadEllis(id){
      console.log('Loading Ellis' , id)
       const date = new Date();
       const year = date.getFullYear();
       const month = date.toLocaleString('default', { month: 'long' });
       this.EllisRef = this.afs.collection('stats/'+ id + '/' + year );
       this.Ellis = this.EllisRef.snapshotChanges().pipe(
         map(actions => actions.map(a => {
           const data = a.payload.doc.data();
           const id = a.payload.doc.id;
        
  
   if(data.status == 'Processing'){
    console.log(data.total)
    this.ellisProcessing = data.total
     }
     if(data.status == 'Pre-Approved'){
      console.log(data.total)
      this.ellisPreApproved = data.total
       }
       if(data.status == 'Contacted'){
        console.log(data.total)
        this.ellisContacted = data.total
         }
         if(data.status == 'Contacted - Follow up required'){
          console.log(data.total)
          this.ellisFollowUp = data.total
           }
           if(data.status == 'Future Opportunity'){
            console.log(data.total)
            this.ellisfuture = data.total
             }
             if(data.status == 'Credit Repair'){
              console.log(data.total)
              this.ellisCredit = data.total
               }
               if(data.status == 'Shopping Around'){
                console.log(data.total)
                this.ellisShopping = data.total
                 }
  
           return { id, ...data };
         }))
       );
      }
      loadbyron(id){

        console.log('Loading byron' , id)
         const date = new Date();
         const year = date.getFullYear();
         const month = date.toLocaleString('default', { month: 'long' });
         this.byronStats = this.afs.collection('stats/' + id + '/'+year).valueChanges().subscribe(data => {
          this.byronStat = data;
    
          this.byronStat.forEach(item => {
    
            if(item.status == 'Processing'){
              console.log(item)
              this.byronProcessing = item
              console.log(this.byronProcessing)
               }
               if(item.status == 'Pre-Approved'){
                console.log(item)
                this.byronPreApproved = item
                 }
                 if(item.status == 'Contacted'){
                  console.log(item)
                  this.byronContacted = item
                   }
                   if(item.status == 'Contacted - Follow up required'){
                    console.log(item)
                    this.byronFollowUp = item
                     }
                     if(item.status == 'Future Opportunity'){
                      console.log(item)
                      this.byronfuture = item
                       }
                       if(item.status == 'Credit Repair'){
                        console.log(item)
                        this.byronCredit = item
                         }
                         if(item.status == 'Shopping Around'){
                          console.log(item)
                          this.byronShopping = item
                           }
                          
          })})
       
        }
        loadrobin(id){

          console.log('Loading robin' , id)
           const date = new Date();
           const year = date.getFullYear();
           const month = date.toLocaleString('default', { month: 'long' });
           this.robinStats = this.afs.collection('stats/' + id + '/'+year).valueChanges().subscribe(data => {
            this.robinStat = data;
      
            this.robinStat.forEach(item => {
      
              if(item.status == 'Processing'){
                console.log(item)
                this.robinProcessing = item
                console.log(this.robinProcessing)
                 }
                 if(item.status == 'Pre-Approved'){
                  console.log(item)
                  this.robinPreApproved = item
                   }
                   if(item.status == 'Contacted'){
                    console.log(item)
                    this.robinContacted = item
                     }
                     if(item.status == 'Contacted - Follow up required'){
                      console.log(item)
                      this.robinFollowUp = item
                       }
                       if(item.status == 'Future Opportunity'){
                        console.log(item)
                        this.robinfuture = item
                         }
                         if(item.status == 'Credit Repair'){
                          console.log(item)
                          this.robinCredit = item
                           }
                           if(item.status == 'Shopping Around'){
                            console.log(item)
                            this.robinShopping = item
                             }
                            
            })})
         
          }
          loadwhitney(id){

            console.log('Loading whitney' , id)
             const date = new Date();
             const year = date.getFullYear();
             const month = date.toLocaleString('default', { month: 'long' });
             this.whitneyStats = this.afs.collection('stats/' + id + '/'+year).valueChanges().subscribe(data => {
              this.whitneyStat = data;
        
              this.whitneyStat.forEach(item => {
        
                if(item.status == 'Processing'){
                  console.log(item)
                  this.whitneyProcessing = item
                  console.log(this.whitneyProcessing)
                   }
                   if(item.status == 'Pre-Approved'){
                    console.log(item)
                    this.whitneyPreApproved = item
                     }
                     if(item.status == 'Contacted'){
                      console.log(item)
                      this.whitneyContacted = item
                       }
                       if(item.status == 'Contacted - Follow up required'){
                        console.log(item)
                        this.whitneyFollowUp = item
                         }
                         if(item.status == 'Future Opportunity'){
                          console.log(item)
                          this.whitneyfuture = item
                           }
                           if(item.status == 'Credit Repair'){
                            console.log(item)
                            this.whitneyCredit = item
                             }
                             if(item.status == 'Shopping Around'){
                              console.log(item)
                              this.whitneyShopping = item
                               }
                              
              })})
           
            }
            loadtravis(id){

              console.log('Loading travis' , id)
               const date = new Date();
               const year = date.getFullYear();
               const month = date.toLocaleString('default', { month: 'long' });
               this.travisStats = this.afs.collection('stats/' + id + '/'+year).valueChanges().subscribe(data => {
                this.travisStat = data;
          
                this.travisStat.forEach(item => {
          
                  if(item.status == 'Processing'){
                    console.log(item)
                    this.travisProcessing = item
                    console.log(this.travisProcessing)
                     }
                     if(item.status == 'Pre-Approved'){
                      console.log(item)
                      this.travisPreApproved = item
                       }
                       if(item.status == 'Contacted'){
                        console.log(item)
                        this.travisContacted = item
                         }
                         if(item.status == 'Contacted - Follow up required'){
                          console.log(item)
                          this.travisFollowUp = item
                           }
                           if(item.status == 'Future Opportunity'){
                            console.log(item)
                            this.travisfuture = item
                             }
                             if(item.status == 'Credit Repair'){
                              console.log(item)
                              this.travisCredit = item
                               }
                               if(item.status == 'Shopping Around'){
                                console.log(item)
                                this.travisShopping = item
                                 }
                                
                })})
             
              }
  ngOnInit(): void {
  }

}
// Wgitney = 27pLlDyylCPP5X8ZcsfqV3JA3Op1
// Keyser = 58P8nrT0tfXePMzohG0OOZYID8f1
// Douglass = 5DtmkJKx2FZosWuVUV9VIyXco6B3

// cqoiApbHnmXg8hiiThhni9vHEVs1
