import { Component, OnInit, AfterViewInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { AngularFireStorage } from "@angular/fire/storage";
import { ActivatedRoute } from "@angular/router";
import Chart from "chart.js";
import { map } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";
import { DataService } from "src/app/shared/services/data.service";

@Component({
  selector: "app-card-line-chart",
  templateUrl: "./card-line-chart.component.html",
})
export class CardLineChartComponent implements OnInit {
 
  stats: any;
  contacted: any[];
  dnc: any[];
  myLeads: any;
  statsRef: any;
  closedValue: any[];
  leads: any[];
  contactedAttempt: any[];
  gal: any[];
  

  constructor( private route: ActivatedRoute,
    public authService: AuthService, 
    public dataService: DataService,
    private afStorage: AngularFireStorage, 
    public afs: AngularFirestore, 
    public afAuth: AngularFireAuth,) {}

  ngOnInit() {}
  ngAfterViewInit() {
  // this.notApproved = []
  this.closedValue = []
  this.contacted = []
  this.dnc = []
  this.leads = []
  this.gal = []
  this.contactedAttempt = []
    this.afAuth.authState.subscribe(user => {
      if (user) {
     
    this.statsRef = this.afs.collection('stats/'+ user.uid + '/2021').valueChanges().subscribe(data => {
      this.stats = data;
      this.stats.forEach(item => {
if (item.status == 'Agent Contacted'){
this.contacted.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
// console.log(this.contacted)
}
if (item.status == 'Attempting Contact'){
  this.contactedAttempt.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
  // console.log(this.contacted)
  }
if (item.status == 'DNC Agent'){
  this.dnc.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
  // console.log(this.dnc)
  }
  if (item.status == 'Lead'){
    this.leads.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
    // console.log(this.leads)
    }
    if (item.status == 'Gave Us A Lead'){
      this.gal.push(item.January, item.February, item.March, item.April, item.May, item.June, item.July, item.August, item.September, item.October, item.November, item.December)
      // console.log(this.leads)
      }
      });
     
    var config = {
      type: "line",
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],
        datasets: [
         
       
          {
            // new Date().getFullYear() - 1,
            label: 'DNC',
            fill: false,
            backgroundColor: "#D1230F",
            borderColor: "#D1230F",
            data: this.dnc,
            barThickness: 24,
            type:'bar'
          },
          {
            // new Date().getFullYear() - 2
            label: 'Attempting Contact',
            fill: false,
            backgroundColor: "orange",
            borderColor: "orange",
            data: this.contactedAttempt,
            barThickness: 24,
            type:'bar'
          },
          {
            // new Date().getFullYear()
            label: 'Contacted',
            backgroundColor: "#0F93D1",
            borderColor: "#0F93D1",
            data: this.contacted,
            fill: false,
            barThickness: 24,
            type:'bar'
          },
          {
            // new Date().getFullYear() - 2
            label: 'Agents with leads',
            fill: false,
            backgroundColor: "green",
            borderColor: "green",
            data: this.gal,
            barThickness: 24,
            type:'bar'
          },
         
          {
            label: new Date().getFullYear() - 1+ ' Website Leads',
            fill: 'origin',
            backgroundColor: 'rgba(5, 5, 5, 0.80)',
            borderColor: "#000",
            data: [0, 1 , 5, 2, 6, 9, 29, 17,12,11,21,16],
            barThickness: 12,
          
            
          },
          {
            // new Date().getFullYear() - 2
            label: 'Open Leads',
            fill: false,
            backgroundColor: "#fff",
            borderColor: "#fff",
            data: this.leads,
            barThickness: 12,
           
            
          },
        ],
      },
      options: {
        animation: {
          duration: 0
      },
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Sales Charts",
          fontColor: "white",
        },
        legend: {
          reverse:false,
          labels: {
            fontColor: "white",
          },
          align: "end",
          position: "bottom",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              stacked: true,
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Month",
                fontColor: "white",
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(0, 0, 0, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: "rgba(255,255,255,.7)",
              },
              stacked: true,
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
                fontColor: "white",
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: "rgba(255, 255, 255, 0.15)",
                zeroLineColor: "rgba(33, 37, 41, 0)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    let ctx: any = document.getElementById("line-chart") as HTMLCanvasElement;
    ctx = ctx.getContext("2d");
     var gradient = ctx.createLinearGradient(0, 0, 0, 400);
      gradient.addColorStop(0, 'rgba(255, 255, 255, 0.44)');   
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0.50)');
      var newGraph = new Chart(ctx, config);
    if(typeof ctx.destroy != "undefined") ctx.destroy();
    ctx = document.getElementById('line-chart');
    ctx = ctx.getContext("2d");
    new Chart(ctx, config);
    var newGraph = new Chart(ctx, config);
    newGraph.update();
  })

  
}})



  }
 
}
