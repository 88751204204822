import { Injectable, NgZone } from '@angular/core';


import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from "@angular/router";
import { stringify } from 'querystring';
import firebase from 'firebase/app'

import { merge, Observable, of, Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/functions';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthService } from './auth.service';
import { AngularFireStorage } from '@angular/fire/storage';

// import { firestore } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  // this.ts is the full date obj
  ts: Date;
  // this.now is the timeStamp
  created: string;
  // this.created is the pretty time
  now: number;
  profileRef: Subscription;
  profile: any;
  userData: firebase.User;

  leads: any;
  itemCol: AngularFirestoreCollection<any>;
  itemRef: Observable<any>;
  str: string;
  item: any;
  actionsRef: AngularFirestoreCollection<any>;
  actions: any;
  updateRef: Promise<void>;
  actionedRef: Promise<void>;
  deadCol: AngularFirestoreCollection<any>;
  dead: any;
  contactedCol: AngularFirestoreCollection<any>;
  contacted: any;
  attemptingCol: AngularFirestoreCollection<any>;
  attempting: any;
  replyId: string;
  expandActionsRef: AngularFirestoreCollection<any>;
  expandActions: any;
  notesRef: Promise<void>;
  incrementRef: any;
  statsRef: AngularFirestoreCollection<any>;
  stat: any;
  janStats: Subscription;
  September: any;
  stati: any;
  incrementtotal: Promise<void>;
  leadsRef: AngularFirestoreCollection<any>;
  agentLead: any;
  isChecked: boolean;
  id: any;
  leadsCol: AngularFirestoreCollection<any>;
  newLeads: any;
  leadid: any;
  leadItemef: Observable<any>;
  leadItemRef: any;
  leadItem: any;
  openCol: AngularFirestoreCollection<any>;
  openLeads: any;
  delete: Promise<void>;
  stats: any[];
  statistices: Subscription;
  calls: any;
  myCallStats: any;
  callsMade: any;
  totalLeadValue: any;
  month: string;
  year: number;
  totalLeads: any;
  thisMonth: any;
  allStat: any;
  allTotals: any;
  totalLeadCount: any;
  allClosed: any;
  allContacted: any;
  allProcessing: any;
  allPreApproved: any;
  allFollowUp: any;
  allCredit: any;
  allShopping: any;
  allfuture: any;
  leadContactedRef: AngularFirestoreCollection<any>;
  contactedLeads: any;
  agentLeaderboardRef: AngularFirestoreCollection<any>;
  agentLeaderboard: any;

  leadSerchTrue: boolean;
  leadSearchRef: AngularFirestoreCollection<any>;
  leadSearch: any;
  processingleadsCol: AngularFirestoreCollection<any>;
  processingLeads: any;
  preleadsCol: AngularFirestoreCollection<any>;
  preLeads: any;
  closedCol: AngularFirestoreCollection<any>;
  closedLeads: any;
  followCol: AngularFirestoreCollection<any>;
  followLeads: any;
  futureCol: AngularFirestoreCollection<any>;
  futureLeads: any;
  creditCol: AngularFirestoreCollection<any>;
  creditLeads: any;
  notApprovedCol: AngularFirestoreCollection<any>;
  notApprovedLeads: any;
  shoppingCol: AngularFirestoreCollection<any>;
  shoppingLeads: any;
  dncCol: AngularFirestoreCollection<any>;
  dncLeads: any;
  deadLeadsCol: AngularFirestoreCollection<any>;
  deadLeads: any;
  allNotApproved: any;
  allDead: any;
  allDNC: any;
  agentLeadsRef: AngularFirestoreCollection<any>;
  agentLeads: any;
  agentLeaderCountRef: AngularFirestoreDocument<any>;
  agentLeaderCount: any;
  agentLeadsData: any;
  agentInfoRef: Subscription;
  agentData: any;
  agentSerchTrue: boolean;
  agentSearchRef: AngularFirestoreCollection<any>;
  agentSearch: any;
  leadData: any;
  oldLoanAmount: any;
 
  LORef: Subscription;
  lo: any[];
  allNew: any;
  contactleadsCol: AngularFirestoreCollection<any>;
  leadsContacted: any;
  allAttempting: any;
  allGave: any;
  allAgentDNC: any;
  allAttenptingContact: any;
  allAgentContacted: any;
  removeLO: Promise<void>;
  DTCCol: AngularFirestoreCollection<any>;
  newLDTCeads: any;
  
  statDat: AngularFirestoreCollection<any>;
  statData: any;
  statStats: any;
  leadCount: any;
  leadCountValue: any;
 
 
  leaderboardCol: AngularFirestoreCollection<any>;
  leaderboard: any;
  leaderGroup$: Subscription;
  statsInfo: any[];
  dataItem: any;
  incrementRef2: Promise<void>;
  teamStats: Subscription;
  teamStat: any;
  confirmation: any;
  ref: any;
  task: any;
  downloadURL: any;
  image: any;
  openLeadsCol: AngularFirestoreCollection<any>;
  openLeadList: any;
  teamActionsRef: AngularFirestoreCollection<any>;
  teamActions: Observable<any[]>;

 
  agentsRef: AngularFirestoreCollection<any>;
  agents: any;
  count: number = 0;
  oldCount: any;
  loRef: any;
  latestDoc: any;
  pipeline: any;
  dtcVABuyersRef: AngularFirestoreCollection<any>;
  buyers: any;
  VAClassRegistrants: Observable<any[]>;
  dtcVABuyersContactedRef: AngularFirestoreCollection<any>;
  VAClassRegistrantsContacted: any;
  consumerDataRef: Subscription;
  consumerData: any;
  consumerContactsRef: Promise<void>;


  teamStatData: any;
  subStatsRef: AngularFirestoreCollection<any>;
  subStats: any;
  statuses: any;
  statTotals: any;
  teamMemberStat: any;
  teamMemberTotal: any;
  statVAConsumersContacted: any;
  totalVAConsumersContacted: any;
  teamstats: any[];

  teamstatistices: Subscription;
  teamsstats: any[];
  newNum: string;
 
  dbSub: Promise<void>;
  teamstatistices2: Subscription;
  teamsstats2: any[];
  teamMember: firebase.firestore.DocumentData;
  teamMemberRef: Promise<void>;
  teamRef: AngularFirestoreCollection<any>;
  team: any;
  teamStats3: Subscription;
  stats3: any[];
  teamStat3: any;
  newArr: any[];
  newStart: any;
  deleteConsumer: Promise<void>;
  firstName: boolean;
  lastName: boolean;
  state: boolean;
  class: boolean;
  dateAdded: boolean;
  lastFifty: boolean;
  dtcVABuyersDistRef: AngularFirestoreCollection<any>;
  VAClassRegistrantsDist: any;
  denverOffice: AngularFirestoreCollection<any>;
  denver: any;
  teamData: Subscription;
  teamMemberData: any[];
  teamDataSet: any;
  attendedSem: boolean;
  latestState: any;
  latestName: any;






 
 












  constructor(public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone,
    public authService: AuthService,
    private functions: AngularFireFunctions,
    private route: ActivatedRoute,
   
    private afStorage: AngularFireStorage, ) {
      this.firstName = false;
      this.lastName = false;
      this.state = false;
      this.class = false;
      this.dateAdded = false;
      this.lastFifty = false;
      this.latestDoc = null;
      this.latestState = null;
    this.latestName = null;
       
       
    this.leadSerchTrue = false;
    this.agentSerchTrue = false;
    // this.route.paramMap.subscribe(params => {
    //   this.replyId = params.get("id")
    // })
    // console.log(this.replyId)
    // this.str = this.replyId.substring(1);
    // console.log( this.str);
    this.isChecked = true;
    // this.ts is the full date obj
    this.ts = new Date();
    // this.now is the timeStamp
    this.created = this.ts.toDateString();
    // this.created is the pretty time
    this.now = Date.now();
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    this.month = month;
    this.year = year;
    this.afAuth.authState.subscribe(user => {
      if (user) {

        this.openCol = this.afs.collection('referrals', ref => ref.where('status', '==', 'New'));
        this.openLeads = this.openCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.denverOffice = this.afs.collection('users/',ref=>ref.where('role','==','LO').where('office','==','Denver'));
        this.denver = this.denverOffice .snapshotChanges().pipe(
          map(actions => actions.map(async a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
          
            return { id, ...data };
          }))
        );
        this.leadContactedRef = this.afs.collection('referrals', ref => ref.where('onwer', '==', user.uid).where('status', '==', 'Contacted'));
        this.contactedLeads = this.leadContactedRef.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.itemCol = this.afs.collection('referrals', ref => ref.where('status', '==', 'New'));
        this.leads = this.itemCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.deadCol = this.afs.collection('users', ref => ref.where('LO', '==', user.uid).where('status', '==', 'DNC Agent'));
        this.dead = this.deadCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
//         this.leaderGroup$ = afs.collectionGroup('2021')
//         .valueChanges().subscribe(data =>{
//           this.statsInfo = data;
//           this.statsInfo.forEach(item=> {
// this.dataItem = item
// console.log(this.dataItem)
//           })
         
//         });


        this.leaderboardCol = this.afs.collection('users', ref => ref.where('role', '==', 'LO').orderBy('closedValue', 'desc'));
        this.leaderboard = this.leaderboardCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            

            return { id, ...data };
          }))
        );
        this.contactedCol = this.afs.collection('users', ref => ref.where('LO', '==', user.uid).where('status', '==', 'Agent Contacted'));
        this.contacted = this.contactedCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.leadsCol = this.afs.collection('referrals', ref => ref.where('LO', '==', user.uid).where('status', '==', 'New'));
        this.newLeads = this.leadsCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
     
        this.DTCCol = this.afs.collection('referrals', ref => ref.where('status', '==', 'New'));
        this.newLDTCeads = this.DTCCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.processingleadsCol = this.afs.collection('referrals', ref => ref.where('LO', '==', user.uid).where('status', '==', 'Processing'));
        this.processingLeads = this.processingleadsCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.contactleadsCol = this.afs.collection('referrals', ref => ref.where('LO', '==', user.uid).where('status', '==', 'Contacted'));
        this.leadsContacted = this.contactleadsCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.preleadsCol = this.afs.collection('referrals', ref => ref.where('LO', '==', user.uid).where('status', '==', 'Pre-Approved'));
        this.preLeads = this.preleadsCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.closedCol = this.afs.collection('referrals', ref => ref.where('LO', '==', user.uid).where('status', '==', 'Closed'));
        this.closedLeads = this.closedCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.followCol = this.afs.collection('referrals', ref => ref.where('LO', '==', user.uid).where('status', '==', 'Contacted - Follow up required'));
        this.followLeads = this.followCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.futureCol = this.afs.collection('referrals', ref => ref.where('LO', '==', user.uid).where('status', '==', 'Future Opportunity'));
        this.futureLeads = this.futureCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.creditCol = this.afs.collection('referrals', ref => ref.where('LO', '==', user.uid).where('status', '==', 'Credit Repair'));
        this.creditLeads = this.creditCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.notApprovedCol = this.afs.collection('referrals', ref => ref.where('LO', '==', user.uid).where('status', '==', 'Not Approved'));
        this.notApprovedLeads = this.notApprovedCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.shoppingCol = this.afs.collection('referrals', ref => ref.where('LO', '==', user.uid).where('status', '==', 'Shopping Around'));
        this.shoppingLeads = this.shoppingCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.dncCol = this.afs.collection('referrals', ref => ref.where('LO', '==', user.uid).where('status', '==', 'DNC'));
        this.dncLeads = this.dncCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.deadLeadsCol = this.afs.collection('referrals', ref => ref.where('LO', '==', user.uid).where('status', '==', 'Dead'));
        this.deadLeads = this.deadLeadsCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.attemptingCol = this.afs.collection('users', ref => ref.where('LO', '==', user.uid).where('status', '==', 'Attempting Contact'));
        this.attempting = this.attemptingCol.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.actionsRef = this.afs.collection('actions', ref => ref.where('user', '==', user.uid).orderBy('dateCreated', 'desc').limit(3));
        this.actions = this.actionsRef.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );


     
     
        
     
        this.teamActionsRef = this.afs.collection('actions',ref=>ref.orderBy('dateCreated', 'desc').limit(10));
        this.teamActions = this.teamActionsRef .snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.leadsRef = this.afs.collection('users', ref => ref.where('LO', '==', user.uid).where('status', '==', 'Gave Us A Lead'));
        this.agentLead = this.leadsRef.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        this.statsRef = this.afs.collection('stats/' + user.uid + '/' + year);
        this.stat = this.statsRef.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            this.September = data;
            console.log(this.September)
            return { id, ...data };
          }))
        );
        this.agentLeaderboardRef = this.afs.collection('users/', ref => ref.where('LO', '==', user.uid).limit(8).orderBy('leadValueCount', 'desc'));
        this.agentLeaderboard = this.agentLeaderboardRef.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
        
        this.dtcVABuyersRef = this.afs.collection('DMSVAConsumers/', ref => ref.where('LO', '==', 'open').orderBy('createdOn','desc'));
        this.VAClassRegistrants = this.dtcVABuyersRef.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;

            return { id, ...data };
          }))
        );
       
        this.dtcVABuyersContactedRef = this.afs.collection('DMSVAConsumers/', ref => ref.where('LO', '==', user.uid).where('contacted','==', true).where('converted','==', false));
        this.VAClassRegistrantsContacted = this.dtcVABuyersContactedRef.snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
          
            return { id, ...data };
          }))
        );
        
        this.teamStats = this.afs.doc('stats/Team/'+year+'/TeamStats').valueChanges().subscribe(data => {
          this.teamStat = data
        })

        this.statistices = this.afs.collection('stats/' + user.uid + '/'+year).valueChanges().subscribe(data => {
          this.stats = data;

          this.stats.forEach(item => {
          
            // if (item) {
            //   this.pipeline = item.total
            //   console.log(this.pipeline)
            // }
            if (item.status == 'calls') {
              this.callsMade = item.total
              this.thisMonth = item.September
              this.allStat = item

            }
            if (item.status == 'Lead') {
              this.totalLeadValue = item
              this.totalLeads = item.total
              this.totalLeadCount = item.totalLeadCount
              this.allTotals = item
              // console.log(this.totalLeadValue)
            }
            if (item.status == 'Closed') {

              this.allClosed = item

            }
            if (item.status == 'Contacted') {

              this.allContacted = item

            }
            if (item.status == 'Processing') {

              this.allProcessing = item

            }
            if (item.status == 'Pre-Approved') {

              this.allPreApproved = item

            }
            if (item.status == 'Contacted - Follow up required') {

              this.allFollowUp = item

            }
            if (item.status == 'Credit Repair') {

              this.allCredit = item

            }
            if (item.status == 'Shopping Around') {

              this.allShopping = item

            }
            if (item.status == 'Future Opportunity') {

              this.allfuture = item

            }
            if (item.status == 'Not Approved') {

              this.allNotApproved = item

            }
            if (item.status == 'Dead') {

              this.allDead = item

            }
            if (item.status == 'DNC') {

              this.allDNC = item

            }
            if (item.status == 'New') {

              this.allNew = item

            }
            if (item.status == 'Agent Contacted') {

              this.allAgentContacted = item

            }
            if (item.status == 'Gave Us A Lead') {

              this.allGave = item

            }
            if (item.status == 'DNC Agent') {

              this.allAgentDNC = item

            }
            if (item.status == 'Attempting Contact') {

              this.allAttenptingContact = item

            }
          })

        });
      }

    })


  }

  loadAgentLeads(agent) {
    this.agentLeadsRef = this.afs.collection('referrals/', ref => ref.where('owner', '==', agent));
    this.agentLeads = this.agentLeadsRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;

        return { id, ...data };
      }))
    );
    this.agentLeaderCountRef = this.afs.doc('users/' + agent);
    this.agentLeaderCount = this.agentLeaderCountRef.valueChanges().subscribe(data => {
      this.agentLeadsData = data
    })
  }
  leadSearchForm(email) {
    this.leadSerchTrue = true;
    this.leadSearchRef = this.afs.collection('referrals', ref => ref.where('email', '==', email));
    this.leadSearch = this.leadSearchRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;

        return { id, ...data };
      }))
    );

  }
  agentSearchForm(email) {
    this.agentSerchTrue = true;
    this.agentSearchRef = this.afs.collection('users', ref => ref.where('array', 'array-contains', email));
    this.agentSearch = this.agentSearchRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;


        return { id, ...data };
      }))
    );

  }
  closeForm() {
    this.leadSerchTrue = false;
    this.agentSerchTrue = false;
  }


  add(accumulator, a) {
    return accumulator + a;
  }
  swal2() {
    Swal.fire(
      'Lead Saved',
      'Taking you to where it lives now..',
      'success'
    ).then(); {
      this.router.navigate(['/admin/leads']);
    }
  }
  submitAgent(firstName, lastName, phone, email, city, state,  notes, str,attended, status, broker, license){
    const first = firstName || 'none'
    const last = lastName|| 'none'
    const phoneNum = phone || 'none'
    const emailAddress = email|| 'none'
    const cityName = city|| 'none'
    const stateName = state|| 'none'
    const note = notes|| 'none'
    const LO = str|| 'none'
    const agentStatus = status|| 'Agent Contacted'
    const brokerName = broker || 'none'
    const licenseNum = license || 'none'
    if (attended == 'on'){
      this.attendedSem = true;
    }
    if (attended == 'off'){
      this.attendedSem = false;
    }
    const date = new Date(); 
    this.now = Date.now();
    const month = date.toLocaleString('default', { month: 'long' });
    const pushkey = this.afs.createId()
    console.log(pushkey)
    this.afs.collection('users').doc(pushkey).set({
      firstName: first,
      lastName: last,
      phone: phoneNum,
      email: emailAddress,
      city: cityName,
      state: stateName,
      notes: note,
      LO: str,
      attended:this.attendedSem,
      certified:this.attendedSem,
      certifiedAgentId: pushkey,
      status: agentStatus,
      uid: pushkey,
      dateCreated: this.now,
      class: 'LO Import',
      broker: brokerName,
      license: licenseNum,
      array: firebase.firestore.FieldValue.arrayUnion(firstName || '', lastName|| '', email|| '', phone|| '', 'LO Import' , state|| ''),
      lastUpdate: month


    },{merge:true}).then();{
      this.statsUpdate(this.authService.profile.uid, agentStatus)
              this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Agent Imported', 'New agent was imported to ' + agentStatus, 'fas fa-thumbs-up', 'bg-green-500', str)
              if (agentStatus == 'Agent Contacted'){
                this.router.navigate(['/admin/agentcontacted']);
              }
              if (agentStatus == 'Attempting Contact'){
                this.router.navigate(['/admin/attempting']);
              }
              if (agentStatus == 'Gave Us A Lead'){
                this.router.navigate(['/admin/sentleads']);
              }
           
              
    }
  }
  submitLead(firstName, lastName, phone, email, address, city, state, zip, futureAddress, futureCity, futureState, futureZip, notes, user, military, loan, lo, agent) {
const loanAmount = loan.replace(/\D/g,'');
const phoneNum = phone.replace(/\D/g,'');
    // console.log(firstName, lastName, phone, email, address, city, state, zip, futureAddress, futureCity, futureState, futureZip, notes)
    this.afs.collection('referrals').add({
      firstName: firstName,
      lastName: lastName,
      phone: phoneNum,
      email: email,
      address: address,
      city: city,
      state: state,
      zip: zip,
      futureAddress: futureAddress,
      futureCity: futureCity,
      futureState: futureState,
      futureZip: futureZip,
      notes: notes,
      owner: agent,
      dateCreated: this.now,
      lastUpdate: this.now,
      date: this.created,
      loanAmount: loanAmount,
      isMilitary: military,
      status: 'New',
      LO: user
    }).then(() => {
      // this.agentUpdateCounter(this.authService.profile.uid, loan)
      this.leadNotification(this.authService.profile.uid, 1)
      // this.totalLeadCounter(this.authService.profile.uid, loan)
      this.leadStatUpdate(this.authService.profile.uid, 'Lead', loan)
      this.leadStatUpdate(this.authService.profile.uid, 'New', loan)
      this.swal2()
      console.log('posted from dataService')
    })
  }

  goToLead(id) {
    this.router.navigate(['/admin/contact/:' + id]);

  }
  goToLeadPage(id) {
    this.router.navigate(['/admin/lead/:' + id]);
  }
  createLead(id) {
    this.router.navigate(['/admin/create']);
  }
  loadContact(id) {
    this.itemRef = this.afs.doc('users/' + id).valueChanges();
    this.itemRef.subscribe(doc => {
      this.item = doc
      this.id = id
    })

  }
  loadLead(id) {
    this.leadItemRef = this.afs.doc('referrals/' + id).valueChanges();
    this.leadItemRef.subscribe(doc => {
      this.leadItem = doc
      this.leadid = id
      this.agentInfoRef = this.afs.doc('users/' + this.leadItem.owner).valueChanges().subscribe(data => {
        this.agentData = data

      })
    })

  }
  logLead(user, firstName, lastName, title, description, icon, color) {
    this.now = Date.now();
    this.afs.collection('actions').add({
      user: user,
      title: title,
      description: description,
      by: firstName + ' ' + lastName,
      dateCreated: this.now,
      icon: icon,
      color: color,

    })
  }
  logAction(user, firstName, lastName, title, description, icon, color, id) {
    this.now = Date.now();
    this.afs.collection('actions').add({
      user: user,
      title: title,
      description: description,
      by: firstName + ' ' + lastName,
      dateCreated: this.now,
      icon: icon,
      color: color,
      id: id
    })
  }
  logNote(user, firstName, lastName, title, description, icon, color, id, note) {
    this.now = Date.now();
    this.afs.collection('actions').add({
      user: user,
      title: title,
      description: description,
      by: firstName + ' ' + lastName,
      dateCreated: this.now,
      icon: icon,
      color: color,
      id: id,
      note: note
    })
  }
  async newNotes(id) {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Add Notes (optional)',
      inputPlaceholder: 'Type your notes here... Click OK to save notes or to exit with out saving.',
      inputAttributes: {
        'aria-label': 'Type your notes here..'
      },

    })

    if (text) {
      Swal.fire(text)

      // this.updateRef = this.afs.doc('users/'+ id).set({
      //  notes:text
      // }, {merge: true})
      this.logNote(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Agent Note Added', this.authService.profile.firstName + ' added a new note to an agent profile.', 'fas fa-edit', 'bg-blue-600', id, text)

    }
  }
  async notes(id) {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Add Notes (optional)',
      inputPlaceholder: 'Type your notes here... Click OK to save notes or to exit with out saving.',
      inputAttributes: {
        'aria-label': 'Type your notes here..'
      },

    })

    if (text) {
      Swal.fire(text)
      this.updateRef = this.afs.doc('users/' + id).set({
        notes: text
      }, { merge: true })
    }
  }
  async leadNotes(id) {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Add Notes (optional)',
      inputPlaceholder: 'Type your notes here... Click OK to save notes or to exit with out saving.',
      inputAttributes: {
        'aria-label': 'Type your notes here..'
      },

    })

    if (text) {
      Swal.fire(text)
      this.logNote(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Lead Note Added', this.authService.profile.firstName + ' added a new note to a lead.', 'fas fa-edit', 'bg-blue-600', id, text)

      // this.updateRef = this.afs.doc('referrals/'+ id).update({
      //  notes:text
      //                   })
    }
  }
  async status(id, status, lastUpdate) {
    const date = new Date();  // 2009-11-10
    //  const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    console.log(this.authService.profile.uid, status)
    const { value: fruit } = await Swal.fire({
      title: 'Select a new status',
      input: 'select',
      inputOptions: {
        'Agent Status': {
          'Gave Us A Lead': 'Gave Us A Lead',
          'Agent Contacted': 'Agent Contacted',
          'Attempting Contact': 'Attempting Contact',
          'DNC Agent': 'DNC Agent'
        },
        'Remove Agent': {
          remove: 'Remove Agent',
        },
        
      },
      inputPlaceholder: 'Select a status',
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise(() => {
          console.log(id, value)
          this.updateRef = this.afs.doc('users/' + id).set({
            status: value,
            LO: this.authService.profile.uid,
            lastUpdate: month
          }, { merge: true })
          if (!status) {
            this.callStats(this.authService.profile.uid)
          }
          if (value == 'remove') {
            this.removeAgentFromLO(id)
            this.decrementAgentStatus(this.authService.profile.uid, status)
            this.statsUpdate(this.authService.profile.uid, value)
            this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Agent removed from LO', 'fas fa-times', 'bg-red-500', id)
            this.newNotes(id)
          }
          if (value == 'DNC Agent') {
            if (status != value) {
              if (lastUpdate == month) {
                this.decrementAgentStatus(this.authService.profile.uid, status)
              }

              this.statsUpdate(this.authService.profile.uid, value)
              this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Agent status changed to ' + value, 'fas fa-edit', 'bg-red-500', id)
              this.newNotes(id)
            } else {
              this.sameStatus(value)

            }
          }
          if (value == 'Agent Contacted') {
            if (status != value) {
              if (lastUpdate == month) {
                this.decrementAgentStatus(this.authService.profile.uid, status)
              }
              this.statsUpdate(this.authService.profile.uid, value)
              this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Agent status changed to ' + value, 'fas fa-edit', 'bg-blue-600', id)
              this.newNotes(id)
            } else {
              this.sameStatus(value)
            }
          }
          if (value == 'Attempting Contact') {
            if (status != value) {
              if (lastUpdate == month) {
                this.decrementAgentStatus(this.authService.profile.uid, status)
              }
              this.statsUpdate(this.authService.profile.uid, value)
              this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Agent status changed to ' + value, 'fas fa-edit', 'bg-orange-500', id)
              this.newNotes(id)
            } else {
              this.sameStatus(value)
            }
          }
          if (value == 'Gave Us A Lead') {
            if (status != value) {
              if (lastUpdate == month) {
                this.decrementAgentStatus(this.authService.profile.uid, status)
              }
              this.statsUpdate(this.authService.profile.uid, value)
              this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Agent status changed to ' + value, 'fas fa-edit', 'bg-green-500', id)
              this.newNotes(id)
            } else {
              this.sameStatus(value)
            }
          }

        })
      }
    })


  }
  async sameStatus(value) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,

    })

    Toast.fire({
      icon: 'error',
      title: 'Status is already ' + value
    })
  }

  async transfer(id, loanAmount, status) {
    console.log(id, loanAmount, status)


  }

  async leadStatus(id, loan, status, lastUpdate) {
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    console.log(id, loan, status)
    const { value: fruit } = await Swal.fire({
      title: 'Select a new status',
      input: 'select',
      inputOptions: {
      'Statuses': {
        'Closed': 'Closed',
        'Processing': 'Processing',
        'Pre-Approved': 'Pre-Approved',
        'Contacted': 'Contacted',
        'Contacted - Follow up required': 'Contacted - Follow up required',
        'Future Opportunity': 'Future Opportunity',
        'Credit Repair': 'Credit Repair',
        'Shopping Around': 'Shopping Around',
        'Not Approved': 'Not Approved',
        'DNC': 'DNC',
        'Dead': 'Dead'
      },
     },
      inputPlaceholder: 'Select a status',
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise(() => {
         
          if (!value) {
          
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,

            })

            Toast.fire({
              icon: 'error',
              title: 'No status chosen..  Please choose a status.'
            })
          }
          if (value){
            this.afs.collection('referrals').doc(id).update({
              status: value,
              lastUpdate: month
            })
          }
        
          if (status == 'New') {
            this.newLeadNotification(this.authService.profile.uid, -1)
            
      
          }
          if (value) {
            if ( value == 'Closed' && status != value ) {
              this.closedCount(this.authService.profile.uid, 1, loan)
            }
            if ( status == 'Closed' && status != value ) {
              this.closedCount(this.authService.profile.uid, -1, -loan)
            }
            if ( status != value ) {
              if (lastUpdate == month) {
                this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
                this.leadStatUpdate(this.authService.profile.uid, value, loan)
              }
              else {
                this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
                this.leadStatUpdate(this.authService.profile.uid, value, loan)
              }
              this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-red-500', id)
              this.leadNotes(id)
            } else {
              this.sameStatus(value)
            }
          }
      //     if (status == 'Closed' && (value == 'Contacted' || value == 'Pre-Approved' || value == 'Processing' || value == 'Credit Repair' || value == 'Future Opportunity' || value == 'Contacted - Follow up required' || value == 'Shopping Around')) {
            
      //       this.leadStatUpdate(this.authService.profile.uid, 'Lead', loan)
      //       this.closedCount(this.authService.profile.uid, -1, -loan)
      //       // this.addLeadBack(this.authService.profile.uid,  loan )
      //     }
      //     if (status == 'Closed' && ( value == 'DNC' || value == 'Dead' || value == 'Not Approved' )) {
      //       console.log(1015)
      //       this.badStatUpdate(this.authService.profile.uid, 'Lead', loan)
      //       this.closedCount(this.authService.profile.uid, -1, -loan)
      //       // this.addLeadBack(this.authService.profile.uid,  loan )
      //     }
        
      //     if (status == 'DNC' || status == 'Dead' || status == 'Not Approved' && (value == 'Contacted' || value == 'Pre-Approved' || value == 'Processing' || value == 'Credit Repair' || value == 'Future Opportunity' || value == 'Contacted - Follow up required' || value == 'Shopping Around')) {
           
      //       this.leadStatUpdate(this.authService.profile.uid, 'Lead', loan)
      //       // this.closedCount(this.authService.profile.uid, -1, -loan)
      //       // this.addLeadBack(this.authService.profile.uid,  loan )
      //     }
   
      //     if (value == 'DNC' || value == 'Not Approved' ||  value == 'Dead' && (status != 'Closed')) {
      //       if (status != value) {
      //         if (lastUpdate == month) {
      //           this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         else {
      //           this.removeLeadNewMonth(this.authService.profile.uid, -loan)
      //           this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-red-500', id)
      //         this.leadNotes(id)
      //       } else {
      //         this.sameStatus(value)
      //       }
      //    }
      //    if (value == 'DNC' || value == 'Not Approved' ||  value == 'Dead' && (status == 'Closed')) {
      //     if (status != value) {
      //       if (lastUpdate == month) {
      //         this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
      //         this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //       }
      //       else {
      //         this.removeLeadNewMonth(this.authService.profile.uid, -loan)
      //         this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
      //         this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //       }
      //       this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-red-500', id)
      //       this.leadNotes(id)
      //     } else {
      //       this.sameStatus(value)
      //     }
      //  }
      //   if (value == 'Not Approved') {
      //   //     if (status != value) {
      //   //       if (lastUpdate == month) {
                
      //   //         this.removeLead(this.authService.profile.uid, -loan)  //--Removes from lead totals
      //   //         this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
      //   //         this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //   //       }
      //   //       else {
               
      //   //         this.removeLeadNewMonth(this.authService.profile.uid, -loan)
      //   //         this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
      //   //         this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //   //       }
      //   //       this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-red-500', id)
      //   //       this.leadNotes(id)
              
      //   //     } else {
      //   //       this.sameStatus(value)
      //   //     }
      //   //   }
      //   //      if (value == 'Dead') {
      //   //     if (status != value) {

      //   //       if (lastUpdate == month) {
      //   //         this.removeLead(this.authService.profile.uid, -loan)  //--Removes from lead totals
      //   //         this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
      //   //         this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //   //       }
      //   //       else {
      //   //         this.removeLeadNewMonth(this.authService.profile.uid, -loan)
      //   //         this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
      //   //         this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //   //       }
      //   //       this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-red-500', id)
      //   //       this.leadNotes(id)
      //   //     } else {
      //   //       this.sameStatus(value)
      //   //     }
      //     }
      //     if (value == 'Contacted') {
      //       if (status != value) {
      //         if (lastUpdate == month) {
      //           this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         else {
      //           this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-green-500', id)
      //         this.leadNotes(id)
      //       } else {
      //         this.sameStatus(value)
      //       }
      //     }
      //     if (value == 'Pre-Approved') {
      //       if (status != value) {
      //         if (lastUpdate == month) {
      //           this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         else {
      //           this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-green-500', id)
      //         this.leadNotes(id)
      //       } else {
      //         this.sameStatus(value)
      //       }
      //     }
      //     if (value == 'Processing') {
      //       if (status != value) {
      //         if (lastUpdate == month) {
      //           this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         else {
      //           this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-green-500', id)
      //         this.leadNotes(id)
      //       } else {
      //         this.sameStatus(value)
      //       }
      //     }
      //     if (value == 'Closed' && ( status != 'Not Approved' || status != 'Dead' || status != 'DNC')) {
      //       if (status != value) {
      //         this.closedCount(this.authService.profile.uid, 1, loan)
      //         if (lastUpdate == month) {
      //           this.removeLead(this.authService.profile.uid, -loan)  //--Removes from lead totals
      //           this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
                
      //         }
      //         else {
      //           this.removeLeadNewMonth(this.authService.profile.uid, -loan)
      //           this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-green-500', id)
      //         this.leadNotes(id)
      //       } else {
      //         this.sameStatus(value)
      //       }
      //     }
      //     if (value == 'Closed' && ( status == 'Not Approved' || status == 'Dead' || status == 'DNC')) {
      //       if (status != value) {
      //         this.closedCount(this.authService.profile.uid, 1, loan)
      //         if (lastUpdate == month) {
      //           // this.removeLead(this.authService.profile.uid, -loan)  //--Removes from lead totals
      //           this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
                
      //         }
      //         else {
      //           this.removeLeadNewMonth(this.authService.profile.uid, -loan)
      //           this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-green-500', id)
      //         this.leadNotes(id)
      //       } else {
      //         this.sameStatus(value)
      //       }
      //     }
      //     if (value == 'Contacted - Follow up required') {
      //       if (status != value) {
      //         if (lastUpdate == month) {
      //           this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         else {
      //           this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-green-500', id)
      //         this.leadNotes(id)
      //       } else {
      //         this.sameStatus(value)
      //       }
      //     }
      //     if (value == 'Future Opportunity') {
      //       if (status != value) {
      //         if (lastUpdate == month) {
      //           this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         else {
      //           this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-green-500', id)
      //         this.leadNotes(id)
      //       } else {
      //         this.sameStatus(value)
      //       }
      //     }
      //     if (value == 'Credit Repair') {
      //       if (status != value) {
      //         if (lastUpdate == month) {
      //           this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         else {
      //           this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-orange-500', id)
      //         this.leadNotes(id)
      //       } else {
      //         this.sameStatus(value)
      //       }
      //     }
          
      //     if (value == 'Shopping Around') {
      //       if (status != value) {
      //         if (lastUpdate == month) {
      //           this.decrementLeadStatus(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         else {
      //           this.decrementLeadStatusTotal(this.authService.profile.uid, status, -loan)
      //           this.leadStatUpdate(this.authService.profile.uid, value, loan)
      //         }
      //         this.logAction(this.authService.profile.uid, this.authService.profile.firstName, this.authService.profile.lastName, 'Status Change', 'Lead status changed to ' + value, 'fas fa-edit', 'bg-red-500', id)
      //         this.leadNotes(id)
      //       } else {
      //         this.sameStatus(value)
      //       }
      //     }
       
        })
      }
    })
  }

  async editLoanData(element, path, id) {
    this.statistices = this.afs.doc('referrals/' + id).valueChanges().subscribe(async data => {
      this.leadData = data
      this.oldLoanAmount = this.leadData.loanAmount;
     
      console.log(this.oldLoanAmount)
      const date = new Date();  // 2009-11-10
      const month = date.toLocaleString('default', { month: 'long' });
      const { value: text } = await Swal.fire({
        input: 'text',
        inputLabel: 'Edit ' + element,
        inputPlaceholder: 'New Info Here',
        inputAttributes: {
          'aria-label': 'Type your new data here'
        },
        showCancelButton: true
      })
      if (text) {
        const x = text;
        const loan: number = +x;
        const diffirence = loan - this.oldLoanAmount
        // Swal.fire(text)
        this.updateRef = this.afs.doc('referrals/' + id).set({
          [path]: loan,
          lastUpdate: month
        }, { merge: true })
        this.leadStatUpdate(this.authService.profile.uid, 'Lead', -this.oldLoanAmount)
        this.leadStatUpdate(this.authService.profile.uid, 'Lead', loan)
        if (this.leadData.lastUpdate == month) {
          this.decrementLeadStatus(this.authService.profile.uid, this.leadData.status, -this.oldLoanAmount)
          this.leadStatUpdate(this.authService.profile.uid, this.leadData.status, loan)
        }
        else {
          this.decrementLeadStatusTotal(this.authService.profile.uid, this.leadData.status, -this.oldLoanAmount)
          this.leadStatUpdate(this.authService.profile.uid, this.leadData.status, loan)
        }
      
      }
      Swal.fire(
        'Loan Amount Updated!',
        // 'You clicked the button!',
        'success'
      )
    })
  }
  
  async editLeadData(element, path, id) {
    console.log(element, path, id)
    const { value: text } = await Swal.fire({
      input: 'text',
      inputLabel: 'Edit ' + element,
      inputPlaceholder: 'New Info Here',
      inputAttributes: {
        'aria-label': 'Type your new data here'
      },
      showCancelButton: true
    })
    if (text) {
      Swal.fire(text)
      this.updateRef = this.afs.doc('referrals/' + id).set({
        [path]: text,
      }, { merge: true })
    }
  }
  testData() {
    console.log(this.authService.profile.uid)
    const date = new Date();  // 2009-11-10
    const month = date.toLocaleString('default', { month: 'long' });
    console.log(month);
  }
  callStats(user) {
    console.log('callStats')
    const date = new Date();  // 2009-11-10

    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(1);
    this.incrementRef = this.afs.doc('stats/' + user + '/'+year+'/calls').set({
      [month]: increment,
      status: 'calls',
      total: increment
    }, { merge: true })
  }
  statsUpdate(user, stat) {
    console.log('StatsUpdate')
    const date = new Date();  // 2009-11-10
  
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    console.log(stat);
    const increment = firebase.firestore.FieldValue.increment(1);

    this.incrementRef = this.afs.doc('stats/' + user + '/' + year + '/'+  stat).set({
      [month]: increment,
      status: stat,
      total: increment
    }, { merge: true })
  }
  leadStatUpdate(user, stat, loan) {
    console.log('leadStatUpdate')
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(1);
    const valueIncrement = firebase.firestore.FieldValue.increment(loan);
    this.incrementRef = this.afs.doc('stats/' + user + '/' + year + '/'+  stat).set({
      [month]: increment,
      status: stat,
      value: valueIncrement,
      [month + 'Value']: valueIncrement,
      total: increment

    }, { merge: true })
    

  }
  badStatUpdate(user, stat, loan) {
    console.log('badStatUpdate')
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(1);
    const valueIncrement = firebase.firestore.FieldValue.increment(loan);
    this.incrementRef = this.afs.doc('stats/' + user + '/' + year + '/'+  stat).set({
      [month]: increment,
      status: stat,
    
      [month + 'Value']: valueIncrement,
     

    }, { merge: true })
    console.log(stat)

  }
  leadStatMonthUpdate(user, stat, loan) {
    console.log('leadStatMonthUpdate')
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(1);
    const valueIncrement = firebase.firestore.FieldValue.increment(loan);
    this.incrementRef = this.afs.doc('stats/' + user + '/' + year + '/'+  stat).set({
      [month]: increment,
      status: stat,
      [month + 'Value']: valueIncrement,

    }, { merge: true })
    console.log(stat)

  }
  leadStatTotalUpdate(user, stat, loan) {
    console.log('leadStatTotalUpdate')
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(1);
    const valueIncrement = firebase.firestore.FieldValue.increment(loan);
    this.incrementRef = this.afs.doc('stats/' + user + '/' + year + '/'+  stat).set({
      status: stat,
      value: valueIncrement,
      total: increment
    }, { merge: true })
    console.log(stat)

  }
  lead(id) {
    this.router.navigate(['/admin/create/:' + id]);
    console.log(id)
  }
  deleteLead(id) {
    console.log(id)
    this.delete = this.afs.doc('referrals/' + id).delete()
  }
  decrementAgentStatus(user, status) {
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(-1);

    this.incrementRef = this.afs.doc('stats/' + user + '/' +year+ '/' + status).set({
      [month]: increment,
      status: status,

      total: increment


    }, { merge: true })
   
    // this.incrementtotal = this.afs.doc('stats/'+ user + '/'+ year + '/totals' ).set({
    //   [stat]:increment,
    //   [month]:month

    // }, {merge: true})
  }
  decrementLeadStatus(user, status, loan) {
    console.log('decrementLeadStatus')
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(-1);
    const valueIncrement = firebase.firestore.FieldValue.increment(loan);

    this.incrementRef = this.afs.doc('stats/' + user + '/' + year + '/'+  status).set({
      [month]: increment,
      status: status,
      value: valueIncrement,
      [month + 'Value']: valueIncrement,
      total: increment


    }, { merge: true })
   
    // this.incrementtotal = this.afs.doc('stats/'+ user + '/'+ year + '/totals' ).set({
    //   [stat]:increment,
    //   [month]:month

    // }, {merge: true})
  }
  decrementLeadStatusTotal(user, status, loan) {
    console.log('decrementLeadStatusTotal')
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(-1);
    const valueIncrement = firebase.firestore.FieldValue.increment(loan);

    this.incrementRef = this.afs.doc('stats/' + user + '/' + year + '/'+  status).set({

      status: status,
      value: valueIncrement,

      total: increment


    }, { merge: true })
   
  }
  decrement(user, status, loan) {
    console.log('decrement')
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(-1);
    const valueIncrement = firebase.firestore.FieldValue.increment(loan);
    this.incrementRef = this.afs.doc('stats/' + user + '/' + year + '/'+ status).set({
      [month]: increment,
      status: status,
      value: valueIncrement,
      [month + 'Value']: valueIncrement,
      total: increment


    }, { merge: true })
   
    // this.incrementtotal = this.afs.doc('stats/'+ user + '/'+ year + '/totals' ).set({
    //   [stat]:increment,
    //   [month]:month

    // }, {merge: true})
  }
  addLeadBack(user, loan) {
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(1);
    const valueIncrement = firebase.firestore.FieldValue.increment(loan);
    this.incrementRef = this.afs.doc('stats/' + user + '/' +year+ '/Lead').set({
      [month]: increment,
      value: valueIncrement,
      [month + 'Value']: valueIncrement,
      total: increment


    }, { merge: true })
   
  }
  removeLead(user, loan) {
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(-1);
    const valueIncrement = firebase.firestore.FieldValue.increment(loan);
    this.incrementRef = this.afs.doc('stats/' + user + '/' +year+ '/Lead').set({
      [month]: increment,
      value: valueIncrement,
      [month + 'Value']: valueIncrement,
      total: increment


    }, { merge: true })
   
  }
  removeLeadNewMonth(user, loan) {
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(-1);
    const valueIncrement = firebase.firestore.FieldValue.increment(loan);
    this.incrementRef = this.afs.doc('stats/' + user + '/' +year+ '/Lead').set({

      value: valueIncrement,
      total: increment


    }, { merge: true })
 
  }
  totalLeadCounter(user, loan) {
    console.log('totalLeadCounter')
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(1);
    const valueIncrement = firebase.firestore.FieldValue.increment(loan);
    this.incrementRef = this.afs.doc('stats/' + user + '/' +year+ '/Lead').set({
      totalLeadCount: increment,
      [month + 'totalLeadCount']: increment,
      totalLeadValue: valueIncrement,
      [month + 'totalLeadValue']: valueIncrement
    }, { merge: true })
  }
  agentUpdateCounter(agent, loan, user) {
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const increment = firebase.firestore.FieldValue.increment(1);
    const valueIncrement = firebase.firestore.FieldValue.increment(loan);
    this.incrementRef = this.afs.doc('users/' + agent).update({
      leadsCount: increment,
      leadValueCount: valueIncrement,
      LO: user

    })

  }
  leadNotification(user, amount) {
    const date = new Date();  // 2009-11-10
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const increment = firebase.firestore.FieldValue.increment(amount);
    const year = date.getFullYear();
    this.incrementRef = this.afs.doc('users/' + user).update({
      leadsCount: increment,
      newLeadCount: increment,
    })
    this.incrementRef2 = this.afs.doc('stats/Team/'+ year +'/TeamStats').set({
      totalLeadCount: increment,
      [month + 'LeadCount']:increment
    }, { merge: true })
  }
  closedCount(user, amount, loanAmount) {
    console.log('closedCount')
    const date = new Date();  // 2009-11-10
    const year = date.getFullYear();
    // const month = date.toLocaleString('default', { month: 'long' });
    const month = date.toLocaleString('default', { month: 'long' });
    const increment = firebase.firestore.FieldValue.increment(amount);
    const value = firebase.firestore.FieldValue.increment(loanAmount);
    this.incrementRef = this.afs.doc('users/' + user).update({
      closedCount: increment,
      closedValue: value


    })
    this.incrementRef2 = this.afs.doc('stats/Team/'+ year +'/TeamStats').set({
      [month + 'ClosedCount']:increment,
      [month + 'ClosedValue']:value,
      closedCount: increment,
      closedValue: value
    }, { merge: true })
  }
  newLeadNotification(user, amount) {
    const increment = firebase.firestore.FieldValue.increment(amount);

    this.incrementRef = this.afs.doc('users/' + user).update({
      // leadsCount: increment,
      newLeadCount: increment,


    })
  }
  removeAgentFromLO(id) {
    this.removeLO = this.afs.doc('users/' + id).update({
      LO: 'open'
    })
  }
  async postToVelocify(LOEmail, first, last, email, phone){
   
  const fun = this.functions.httpsCallable('velocifyAgentPost');
    this.confirmation = await fun({
    
      email: email,
      firstName: first,
      lastName: last,
      phone: phone,
      LOEmail:LOEmail,

  
    }).toPromise();
    return fun;
}
async edit(id,downloadURL,user){
  console.log(downloadURL)
 this.afAuth.authState.subscribe(async user => {
   if(user) {
  console.log(user.uid);
  const {value: file} = await Swal.fire({
   title: 'Select image',
   input: 'file',
   inputAttributes: {
     'accept': 'image/*',
     'aria-label': 'Upload your profile picture'
   }
 })
 
 if (file) {
   const reader = new FileReader
   reader.onload = (e) => {
     console.log(e.target["result"])
     Swal.fire({
       title: 'Confirm Upload',
      //  imageUrl: e.target["result"] ,
      //  imageAlt: 'The uploaded picture',
       
     })
     const id = Math.random().toString(36).substring(2);
           this.ref = this.afStorage.ref(id);
           this.task = this.ref.put(file);
           this.task.snapshotChanges().pipe(
             finalize(() => {
               this.downloadURL = this.ref.getDownloadURL()
               this.downloadURL.subscribe(url =>{
                 (this.image = url)
                 console.log(url)
                 const foodRef = this.afs.doc('users/'+ user.uid);
                 foodRef.update({
               
                  photoURL: url,
                 
                 })
               } );
             
             }),
             
           ).subscribe() 
         
   }
   
   reader.readAsDataURL(file)
 }
}
})

}

async logCreation(uid, lo){
 
  const LO = lo || 'open';
  console.log(uid, LO)
  const fun = this.functions.httpsCallable('updateUserCreationDate');
      this.confirmation = await fun({
       uid:uid,
      }).toPromise()
      .then();{
this.afs.collection('users').doc(uid).update({
  dateCreated: this.confirmation.newDateTime,
LO: LO
})
      }
      console.log(this.confirmation.newDateTime)
      return fun;
    }

    clickCount(): void{
      this.oldCount = this.count
      this.count++
      const hundo = 100 * this.count
      // console.log(hundo, this.count, this.oldCount)
      this.agentsRef = this.afs.collection('users', ref => ref.limit(10));
      this.agents = this.agentsRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          this.loRef = data.LO  ||  'open'
          console.log(this.loRef)

          return { id, ...data };
        }))
      );
      }
      loadAgents(){
        const db = firebase.firestore()
        this.lastFifty = true;
        this.lastName = false;
        this.state = false;
        this.class = false;
        this.dateAdded = false;
        this.firstName = false
       
        // .orderBy('state').startAfter(this.latestDoc|| 0).limit(50)
        this.agentsRef = this.afs.collection('users', ref => ref.where('LO','==','open').limit(50).orderBy('dateCreated', 'desc').startAfter(this.latestDoc || 10000000000000000000000));
      this.agents = this.agentsRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          this.loRef = data.LO  ||  'open'
          if (!data.array){
            console.log('set array')
          //  this.logCreation(data.uid, this.loRef)

          this.afs.doc('users/'+ id).update({
            array: firebase.firestore.FieldValue.arrayUnion(data.firstName || '', data.lastName|| '', data.email|| '', data.phone|| '', data.class|| '', data.state|| '')
          })
          }
this.latestDoc = data.dateCreated || 0


          return { id, ...data };
        }))
      );
      }
      loadAgentsFirstName(){
        this.lastFifty = false;
        this.lastName = false;
        this.state = false;
        this.class = false;
        this.dateAdded = false;
        this.firstName = true
        console.log(this.latestDoc)
        this.latestName = null || 'A' // .orderBy('state').startAfter(this.latestDoc|| 0).limit(50)
        this.agentsRef = this.afs.collection('users', ref => ref.where('LO','==','open').limit(50).orderBy('firstName').startAfter(this.latestDoc || 0 ));
      this.agents = this.agentsRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          this.loRef = data.LO  ||  'open'
         if (!data.dateCreated){
          this.logCreation(data.uid, this.loRef)
         }
         this.latestDoc = data.firstName || 'A' || 'a'


          return { id, ...data };
        }))
      );
      }
      loadAgentsLastName(){
        this.lastFifty = false;
        this.lastName = true;
        this.state = false;
        this.class = false;
        this.dateAdded = false;
        this.firstName = false
        console.log(this.latestDoc)
        // .orderBy('state').startAfter(this.latestDoc|| 0).limit(50)
        this.agentsRef = this.afs.collection('users', ref => ref.where('LO','==','open').limit(50).orderBy('lastName').startAfter(this.latestDoc|| 0));
      this.agents = this.agentsRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          this.loRef = data.LO  ||  'open'
         if (!data.dateCreated){
          this.logCreation(data.uid, this.loRef)
         }
this.latestDoc = data.lastName || 'A' || 'a'


          return { id, ...data };
        }))
      );
      }
      loadAgentsState(){
        this.lastFifty = false;
        this.lastName = false;
        this.state = true;
        this.class = false;
        this.dateAdded = false;
        this.firstName = false
        console.log(this.latestState)
        // .orderBy('state').startAfter(this.latestDoc|| 0).limit(50)
        this.agentsRef = this.afs.collection('users', ref => ref.where('LO','==','open').limit(150).orderBy('state','asc').startAt(this.latestState|| 0));
      this.agents = this.agentsRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          this.loRef = data.LO  ||  'open'
       
          this.latestState = data.state || 'A'


          return { id, ...data };
        }))
      );
// const ms = Date.parse('2019-05-15 07:11:10.673Z');
// console.log(ms);
//1557904270673
      }
      loadAgentsClass(){
      
        this.lastFifty = false;
        this.lastName = false;
        this.state = false;
        this.class = true;
        this.dateAdded = false;
        this.firstName = false
        console.log(this.latestDoc)
        // .orderBy('state').startAfter(this.latestDoc|| 0).limit(50)
        this.agentsRef = this.afs.collection('users', ref => ref.where('LO','==','open').limit(50).orderBy('class').startAfter(this.latestDoc || 0));
      this.agents = this.agentsRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          this.loRef = data.LO  ||  'open'
         if (!data.dateCreated){
         
          this.logCreation(data.uid, this.loRef)
         }

this.latestDoc = data.class


          return { id, ...data };
        }))
      );
      }
      loadAgentsDate(){
        const db = firebase.firestore()
        this.lastFifty = false;
        this.lastName = false;
        this.state = false;
        this.class = false;
        this.dateAdded = true;
        this.firstName = false
        console.log(this.latestDoc)
        // .orderBy('state').startAfter(this.latestDoc|| 0).limit(50)
        this.agentsRef = this.afs.collection('users', ref => ref.where('LO','==','open').limit(50).orderBy('dateCreated','desc').startAfter(this.latestDoc || 1000000000000000000000));
      this.agents = this.agentsRef.snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          this.loRef = data.LO  ||  'open'
          if (!data.array){
            console.log('set array')
          //  this.logCreation(data.uid, this.loRef)

          this.afs.doc('users/'+ id).update({
            array: firebase.firestore.FieldValue.arrayUnion(data.firstName || '', data.lastName|| '', data.email|| '', data.phone|| '', data.class|| '', data.state|| '')
          })
          }
this.latestDoc = data.dateCreated


          return { id, ...data };
        }))
      );
      }
   
      vaConsumersContacted(id ){
        this.now = Date.now();
        this.afs.collection('DMSVAConsumers').doc(id).update({
          contacted: true,
          contactedOn:  this.now,
          LO: this.authService.profile.uid
        })
        this.VAConsumerContactUpdate( 'VA Consumers Contacted', 1)
      }
      vaConsumersUncontact(id ){
        this.now = Date.now();
        this.afs.collection('DMSVAConsumers').doc(id).update({
          contacted: false,
          contactedOn:  this.now,
          LO: 'open'
        })
        this.VAConsumerContactUpdate( 'VA Consumers Contacted', -1)
      }
      vaConsumersAttended(id , value){
        this.now = Date.now();
        this.afs.collection('DMSVAConsumers').doc(id).update({
          attended: value,
         
         
        })
        if (value == true){
          this.vaAttendaceUpdate(1)
        }
        if (value == false){
          this.vaAttendaceUpdate(-1)
        }
        
      }
      convert2(id, LO){
        console.log(id, LO)
      }
      convert(id, LO){
        this.now = Date.now();
        const increment = firebase.firestore.FieldValue.increment(1);
        console.log('Convert Lead', id)
const db = firebase.firestore()
        var docRef = db.collection("DMSVAConsumers").doc(id);

        docRef.get().then((doc) => {
            if (doc.exists) {
             
                this.consumerData = doc.data();
                
                this.afs.collection('referrals').doc(id).set({
         firstName: this.consumerData.first_name,
         lastName: this.consumerData.last_name,
         phone: this.consumerData.phone,
         email: this.consumerData.email,
         LO: LO,
         veloficyLeadId: this.consumerData.veloficyLeadId,
         status: "New",
         loanAmount: 0,
         class:'DMSVAConsumers',
         createdOn: this.now,
         notes: this.consumerData.notes || '',
         closed: false,
         dead: false,
         exactTime: this.now,
         owner: LO,
         attended: this.consumerData.attended,
         array: firebase.firestore.FieldValue.arrayUnion(this.consumerData.first_name || '', this.consumerData.last_name || '', this.consumerData.email || '', this.consumerData.phone|| '', 'DMSVAConsumers' )

        
         
        },{merge:true})
        this.afs.collection('users').doc(LO).update({
          newLeadCount: increment
        })
        this.afs.collection('DMSVAConsumers/').doc(id).update({
          converted: true
        })
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });


      }
      async vaConsumersOptions(id ){
        const { value: fruit } = await Swal.fire({
          title: 'Options for this registrant',
          input: 'select',
          inputOptions: {
            'Did not Contact?': {
              'Did Not Contact':'Revert back to Uncontacted'
            },
            'Attended?': {
              'Attended': 'Attended Seminar',
             'Did Not Attend': 'Did not attend',
            },
            'Note': {
              'Add A Note': 'Note',
          
            },
            'Convert': {
              'Convert': 'Convert registrant record to a Lead',
            }
          },
          inputPlaceholder: 'Registrant Options',
        
          inputValidator: (value) => {
            return new Promise(() => {
              if (value === 'Did Not Contact') {
                console.log(value)
                this.vaConsumersUncontact(id )
                Swal.fire(`Registant moved back to the Uncontacted list`)
              }
              if (value === 'Attended') {
                console.log(value)
                this.vaConsumersAttended(id, true )
                Swal.fire(`Registrant marked as Attended`)
              } 
              if (value === 'Did Not Attend') {
                console.log(value)
                this.vaConsumersAttended(id, false )
                Swal.fire(`Registrant marked Did Not Attend`)
              } 
              if (value === 'Add A Note') {
                console.log(value)
                // this.vaConsumersNote(id)
               
              } 
              if (value === 'Convert') {
                console.log(value)
                // this.convert(id )
                Swal.fire(
                  'Converting Lead',
                  'Redirecting you to the list of new leads now!',
                  // 'Converting this registrant to a "Lead".  Status has been set to "Contacted". You can access this lead by navigating to the "Contacted" page under "Status" on the left.',
                  'success'
                )
                this.router.navigate(['/admin/leads']);
                
              } 
            })
          }
          
        })
      
       
      
      }
      async vaConsumersDistribution(id ){
       
        
       
      
       
      
      }
      async vaConsumersNote(id,LO){
        const { value: text } = await Swal.fire({
          input: 'textarea',
          inputLabel: 'Add A Note',
          inputPlaceholder: 'Add a note about this registrant',
          inputAttributes: {
            'aria-label': 'Type your message here'
          },
          showCancelButton: true
        })
        
        if (text) {
          Swal.fire(text)
          this.afs.doc('DMSVAConsumers/'+ id).update({
            notes:text,
            LO: LO
          })
        }
      }
      VAConsumerContactUpdate( stat, upDown) {
        console.log('VAConsumerContactUpdate')
        const user = this.authService.profile.uid
        const date = new Date();  // 2009-11-10
        // const month = date.toLocaleString('default', { month: 'long' });
        const amount = upDown
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        
        const increment = firebase.firestore.FieldValue.increment(amount);
       
        this.incrementRef = this.afs.doc('stats/' + user + '/' + year + '/'+  stat).set({
          [month]: increment,
          status: stat,
          total: increment
    
        }, { merge: true })
        
        this.consumerContactsRef = this.afs.doc('users/' + user).update({
          [month + 'VAConsumersContaced']: increment,
       
          ['TotalVAConsumersContaced']: increment
    
        })
       
      }
      vaAttendaceUpdate(upDown){
        const user = this.authService.profile.uid
        const date = new Date();  // 2009-11-10
        // const month = date.toLocaleString('default', { month: 'long' });
        const increment = firebase.firestore.FieldValue.increment(upDown);
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        this.afs.doc('stats/Team/'+ year +'/TeamStats').update({
          totalVAConsumersAttended: increment,
          [month + 'VAConsumersAttended']:increment,
      
         });
      }
      vaConsumersDelete(id ){
        console.log('Deleted ' + id)
        this.deleteConsumer = this.afs.doc('DMSVAConsumers/' + id).delete()
       
      }
      moreLeads(){

      }
   
}


