import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/services/auth.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
})
export class RegisterComponent implements OnInit {
  numnum: any;
  num: any;
  phone: any;
  constructor(private authService: AuthService) {}

  ngOnInit(): void {}
  testNum(num, phone){
    this.num = num
    this.numnum = this.num.replace(/\D/g,'');
    this.phone = phone.replace(/\D/g,'');
    console.log(this.numnum, this.phone)
  }
}
